import service from '../service';

const sendAlarmCertificate = async (
  { email, accountGuid, accessToken }: { email: string, accountGuid: string, accessToken: string }
) => {
  const response = service.post(
    `/account/${accountGuid}/certificate/email`,
    {
      email
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default sendAlarmCertificate;
