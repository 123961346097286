import { useEffect, useLayoutEffect } from 'react';

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  timeout = 500
) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const isChatAvailable = () => {
  // Get date in MST
  const now = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
  );
  // Get current hour in MST
  const hour = now.getHours();
  // Get day of week
  const dayOfWeek = now.getDay();

  // Check we aren't outside business hours for chat
  if ((dayOfWeek === 6 && hour >= 18) || (dayOfWeek === 0 && hour < 7)) {
    return false;
  }
  return true;
};

export const printNumber = (num: number) => {
  if (num % 1 === 0) {
    return num;
  }
  return +num.toFixed(2);
};

export const toTitleCase = (str: string) => {
  if (!str) return '';
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const canUseDOM = () => {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
};

export const useIsomorphicEffect = canUseDOM() ? useLayoutEffect : useEffect;

export const roundTo = (number: number | string, precision = 2) =>
  (Math.round(parseFloat(`${number}` || '0.0') * 100) / 100).toFixed(precision);

export async function sleep(ms: number) {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export async function minDelay<T>(promise: Promise<T>, ms: number) {
  let [p] = await Promise.all([promise, sleep(ms)]);

  return p;
}

export function createCommaList(arr: Array<string>) {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr.join(' and ');

  return arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1);
}
