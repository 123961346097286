/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';

const VisiblyHidden: React.FC<PropsWithChildren> = ({ children }) => {
  const hiddenStyles = css`
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  `;

  return <span css={hiddenStyles}>{children}</span>;
};

export default VisiblyHidden;
