import { css } from '@emotion/react';

const useStyle = () => {
  const prettyPrice = css`
    font-size: 16px;
    line-height: 21px;
    color: inherit;

    & .decimals {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      color: inherit;
    }
  `;

  return { prettyPrice };
};

export default useStyle;
