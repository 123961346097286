import { AmountDue, getAmountDue } from '@customer/services/coveService';
import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import { StoreModel } from '@customer/state/store/model';
import { persist, thunkOn, ThunkOn } from 'easy-peasy';

export interface AmountDueFetchPayload {
  accountsId?: string;
  accountId: string;
  tokenId: string;
}

export interface AmountDueModel
  extends DataModel<{ amountDue: AmountDue }, any, AmountDueFetchPayload> {
  onAccountsSetCurrentId: ThunkOn<AmountDueModel, any, StoreModel>;
}

const amountDue: AmountDueModel = persist(
  {
    ...dataModel(
      async (payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const accountGuid =
          payload?.accountId ||
          storeState?.oneTimeUser?.payload?.accountGuid ||
          payload?.accountsId ||
          storeState?.activeId?.currentId;
        if (!accountGuid) {
          console.log('ACCOUNT_GUID IS NULL');
          return [];
        }

        const accessToken =
          payload?.tokenId ||
          (await storeActions?.oneTimeUser?.getToken?.()) ||
          (await storeActions?.pando?.getToken?.());

        if (!accessToken) {
          console.log('ACCESS_TOKEN IS NULL');
          return [];
        }
        const response = await getAmountDue({ accountGuid }, { accessToken });
        return [{ amountDue: response.data, AcctId: accountGuid }];
      },
      {
        idKey: 'AcctId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.resetMemorizeInitialFetch();
          actions.initialFetch();
        }
      }
    ),
  },
  { deny: ['error'] }
);

export default amountDue;
