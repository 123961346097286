export const base64PdfToBlob = blob => {
  const base64str = blob;
  // decode base64 string, remove space for IE compatibility
  const binary = atob(base64str.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"
  return new Blob([view], { type: 'application/pdf' });
};
