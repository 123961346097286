import service from '../service';

export interface AccountReferralCode {
  code: string;
  url: string;
}

const getAccountReferralCode = async (
  { accountGuid }: { accountGuid: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<AccountReferralCode>(
    `/account/${accountGuid}/referral`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getAccountReferralCode;
