import router from './router';
import user from './user';
import oneTimeUser from './oneTimeUser';
import pando from './pando';
import account from './account';
import activeId from './activeId';

const storeModel = {
  router,
  user: user(),
  oneTimeUser,
  pando,
  account,
  activeId
};

export default storeModel;
