/** @jsxImportSource @emotion/react */
import VisiblyHidden from '../VisiblyHidden';
import useStyle from './style';

const PrettyPrice: React.FC<{ price: number }> = ({ price = 0 }) => {
  const s = useStyle();
  const priceDecimals = parseFloat(`${price}`).toFixed(2);
  const priceSplit = priceDecimals.split('.');
  const left = priceSplit[0];
  const right = priceSplit[1];
  const isNegative = +left < 0;

  return (
    <span css={s.prettyPrice}>
      <span aria-hidden>
        {isNegative && '-'}${Math.abs(+left)}.
        <span className="decimals">{right}</span>
      </span>
      <VisiblyHidden>{`$${priceDecimals}`}</VisiblyHidden>
    </span>
  );
};

export default PrettyPrice;
