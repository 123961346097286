import axios from 'axios';

/**
 * @param {string} zipcode
 * @returns {Promise<{ city: string, state: string } | undefined>}
 */
export const getAddressByZipcode = async zipcode => {
  try {
    const { data } = await axios.get(`https://api.zippopotam.us/us/${zipcode}`);

    if (data.places[0]) {
      return {
        city: data.places[0]['place name'],
        state: data.places[0]['state abbreviation'],
      };
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
};
