import service, { PandoResponse } from '..';

export interface DeliveryInfo {
  OrderId: string;
  DeliveryEta: string;
  PackageStatus: number;
  PackageTracker: string;
  TrackingNumber: string;
  OrderStatus: string;
}

const getDeliveryInfo = async (
  {
    accountGuid,
    orderStatus,
  }: {
    accountGuid: string;
    orderStatus?: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const query = new URLSearchParams(
    JSON.parse(JSON.stringify({ orderStatus }))
  ).toString();
  const response = service.get<PandoResponse<DeliveryInfo[]>>(
    `/api/deliveryInfo`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        AccountGuid: accountGuid,
        Query: query,
      },
    }
  );
  return response;
};

export default getDeliveryInfo;
