import {
  PaymentHistory,
  getPaymentHistory,
} from '@customer/services/pandoService';
import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import { StoreModel } from '@customer/state/store/model';
import {
  Action,
  action,
  persist,
  Thunk,
  thunk,
  ThunkOn,
  thunkOn,
} from 'easy-peasy';

export interface PaymentHistoryFetchPayload {
  accountGuid?: string;
}
export interface PaymentHistoryFetchMorePayload
  extends PaymentHistoryFetchPayload {
  skip?: number;
  limit?: number;
}

export interface PaymentHistoryModel
  extends DataModel<PaymentHistory, any, PaymentHistoryFetchPayload> {
  onAccountsSetCurrentId: ThunkOn<PaymentHistoryModel, any, StoreModel>;
  updatePaymentHistory: Action<
    PaymentHistoryModel,
    PaymentHistoryFetchMorePayload & {
      accountGuid: string;
      data: PaymentHistory;
    }
  >;
  fetchMorePaymentHistory: Thunk<
    PaymentHistoryModel,
    PaymentHistoryFetchMorePayload,
    any,
    StoreModel
  >;
}
const paymentHistory = persist<PaymentHistoryModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId = storeState?.activeId?.currentId || localStorage.getItem('currentId');
        if (currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          payload?.accountGuid ||
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken = await storeActions.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }

        const response = await getPaymentHistory(
          { accountGuid },
          { accessToken }
        );

        return [
          {
            ...response?.data?.Data,
            AcctId: currentId,
          },
        ];
      },
      {
        idKey: 'AcctId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.resetMemorizeInitialFetch();
          actions.initialFetch();
        }
      }
    ),
    updatePaymentHistory: action((state, payload) => {
      const { accountGuid, limit, skip, data } = payload;
      if (!data?.PaymentHistories?.length) return;

      state.data = state.data || {};
      state.data[accountGuid] = state?.data?.[accountGuid] || {
        id: accountGuid,
      };
      state.data[accountGuid].TotalRecords =
        data.TotalRecords || state.data[accountGuid].TotalRecords || 0;
      state.data[accountGuid].PaymentHistories =
        state.data[accountGuid].PaymentHistories || [];
      const start = skip || 0;
      if (start) {
        for (let index = 0; index < start; index++) {
          state.data[accountGuid].PaymentHistories[index] =
            state.data[accountGuid].PaymentHistories[index] || {};
        }
      }

      const prepareRecordsCount = Math.min(
        state.data[accountGuid].TotalRecords,
        limit || data?.PaymentHistories?.length
      );
      for (let index = 0; index < prepareRecordsCount; index++) {
        state.data[accountGuid].PaymentHistories[start + index] =
          data.PaymentHistories[index];
      }
    }),
    fetchMorePaymentHistory: thunk(async (actions, payload, helpers) => {
      const storeActions = helpers.getStoreActions();
      const storeState = helpers.getStoreState();
      const currentId = storeState?.activeId?.currentId || localStorage.getItem('currentId');
      if (currentId === null) {
        console.error('accounts is missing currentId');
        return [];
      }
      const accountGuid =
        payload?.accountGuid ||
        storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

      if (accountGuid == null) {
        console.error(`account (${currentId}) is missing accountGuid`);
        return [];
      }

      const accessToken = await storeActions.pando.getToken();
      if (accessToken == null) {
        console.error(`pando model missing accessToken`);
        return [];
      }

      const loadExtra = (payload?.limit || 10) * 3;
      const limit = Math.max(0, payload?.limit || 10) + loadExtra || 10;
      const skip = Math.max(0, (payload?.skip || 0) - loadExtra);

      const response = await getPaymentHistory(
        { accountGuid, limit, skip },
        { accessToken }
      );

      actions.updatePaymentHistory({
        accountGuid,
        limit,
        skip,
        data: response?.data?.Data,
      });
    }),
  },
  { deny: ['error'] }
);
export default paymentHistory;
