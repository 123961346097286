import service, {
  PandoResponse,
} from '@customer/services/pandoService/service';

export interface AddPaymentMethod {
  CardHolder: string;
  CardNumber: string;
  BillingAddress?: {
    Address1: string;
    Address2: string;
    City: string;
    StateCode: string;
    PostalCode: string;
    CountryCode: string;
  };
  ExpirationMonth: number;

  ExpirationYear: number;

  SecurityCode: string;

  SetAsDefault?: boolean;
}

const getPaymentMethods = async (
  {
    accountGuid,
    ...paymentMethod
  }: {
    accountGuid: string;
  } & AddPaymentMethod,
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<PandoResponse<{ PaymentMethodId: number }>>(
    `/api/addPaymentMethod`,
    { paymentMethod },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accountguid: accountGuid,
      },
    }
  );
  return response;
};

export default getPaymentMethods;
