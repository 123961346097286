import service from '@customer/services/coveService';

export interface AccountMonitoringPlan {
  accountId: string;
  currentPlan: {
    name: string;
    rate: number;
    tax: number;
    rateWithTax: number;
    startDate: number;
  };
  otherPlans: Record<
    string,
    { name: string; rate: number; tax: number; rateWithTax: number }
  >;
}

const getAccountMonitoringPlan = async (
  { accountGuid }: { accountGuid: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<AccountMonitoringPlan>(
    `/account/${accountGuid}/monitoringPlan`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getAccountMonitoringPlan;
