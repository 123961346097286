import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import {
  LinkedAccount,
  getLinkedAccounts,
} from '@customer/services/pandoService';
import { persist } from 'easy-peasy';

export interface AccountsModel
  extends DataModel<LinkedAccount, any, undefined> {}

const accounts: AccountsModel = persist<AccountsModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const state = helpers.getStoreActions();
        const accessToken = await state.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }
        const response = await getLinkedAccounts({ accessToken });
        
        const linkedAccounts = response?.data?.Data;

        // Set the centralized activeId to the first AcctId in the array
        if (linkedAccounts && linkedAccounts.length > 0) {
          let currentId = localStorage.getItem('currentId');
          if (!currentId) {
            currentId = linkedAccounts[0].AcctId;
            localStorage.setItem('currentId', currentId);
          }
          state.activeId.setCurrentId(currentId);
        }

        return linkedAccounts;
      },
      { idKey: 'AcctId' }
    )
  },
  { deny: ['error'] }
);

export default accounts;
