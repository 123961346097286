/** @jsxImportSource @emotion/react */
import { PropsOf } from '@emotion/react';
import { FC } from 'react';
import { Toaster as HotToaster, ToastBar } from 'react-hot-toast';
import { SmoothMount } from '../../UI';
import { toasterConfig } from './toasterConfig';

// For more info on <Toaster /> options: https://react-hot-toast.com/docs/toaster

const Toaster: FC<PropsOf<typeof HotToaster>> = ({
  toastOptions = toasterConfig,
  ...props
}) => {
  return (
    <HotToaster toastOptions={toastOptions} {...props}>
      {t => (
        <SmoothMount
          show={t.visible}
          fade
          style={{ pointerEvents: 'none', width: '100%' }}
        >
          <ToastBar
            style={{
              width: '100%',
              padding: 0,
              boxShadow: 'none',
              overflow: 'visible',
              display: 'flex',
              justifyContent: 'center',
              pointerEvents: 'none',
              borderRadius: '15px',
            }}
            toast={t}
          >
            {toast => {
              return (
                <div
                  style={{
                    padding: '50px 25px',
                    color: '#002334',
                    // textAlign: 'center',
                    width: '100%',
                    maxWidth: '720px',
                    borderRadius: '15px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  {toast.message}
                </div>
              );
            }}
          </ToastBar>
        </SmoothMount>
      )}
    </HotToaster>
  );
};

export default Toaster;
