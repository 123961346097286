import service, { Billing, PandoResponse } from '..';

const updateBillingOption = async (
  {
    accountGuid,
    BillingDayType,
    BillingOptionId,
  }: {
    accountGuid: string;
    BillingDayType: number;
    BillingOptionId: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<PandoResponse<Billing>>(
    `/api/updateBillingOptions`,
    {
      BillingDayType,
      BillingOptionId,
      accountGuid,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default updateBillingOption;
