import { useContext, createContext, PropsWithChildren } from 'react';
import type { Component, Context, FC } from 'react';

export type RouterNavigate = (to: string, options?: any) => Promise<void>;
export type RouterLink = typeof Component;

export interface Router {
  navigate: RouterNavigate;
  Link: RouterLink;
}

export const RouterContext: Context<Router> = createContext({} as Router);

export interface RouterProviderProps extends PropsWithChildren {
  navigate: RouterNavigate;
  Link: RouterLink;
}

export const RouterProvider: FC<RouterProviderProps> = ({
  navigate,
  Link,
  children,
}) => {
  return (
    <RouterContext.Provider value={{ navigate, Link }}>
      {children}
    </RouterContext.Provider>
  );
};

export const useRouter = () => {
  const router = useContext(RouterContext);

  return router;
};
