import service from '@customer/services/coveService/service';
import coveCart from '@shared/cart-react/src/coveCart';

const calculateTotals = async (
  {
    accountGuid,
    cartProducts,
    coveCredit,
    shippingMethod = 'Regular',
    promoCode,
    couponCodes = [],
  }: {
    accountGuid: string;
    cartProducts: {
      sku: string;
      qty: number;
    }[];
    coveCredit?: number;
    shippingMethod?: string;
    promoCode: string;
    couponCodes?: string[];
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<Awaited<ReturnType<typeof coveCart>>>(
    `/account/${accountGuid}/order/calculateTotals`,
    {
      cartProducts,
      coveCredit,
      shippingMethod,
      promoCode,
      couponCodes,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default calculateTotals;
