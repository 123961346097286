import { action, Action } from 'easy-peasy';
import type { HistoryListenerParameter } from '@reach/router';

export type NavigatedState = Record<any, any>;

export interface RouterModel {
  listener: Action<RouterModel, HistoryListenerParameter>;
  loading: boolean;
  setLoading: Action<RouterModel, boolean>;
  navigatedState: Record<string, any>;
  setNavigatedState: Action<RouterModel, NavigatedState>;
}

const router: RouterModel = {
  listener: action((state, payload) => {
    console.log('listener', JSON.stringify({ payload, state }));
  }),
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  navigatedState: {},
  setNavigatedState: action((state, payload) => {
    state.navigatedState = payload;
  }),
};

export default router;
