import {
  CartProduct,
  Coupon,
  Discount,
  ProductRecord,
} from '@shared/cart-js/src/schemas';
import { SavedCart } from './schemas';
import { isEmpty, isUndefined } from 'lodash';

type CartProductSku = string;
type DiscountId = string;
type PromoCodeId = string;

type ProductDiscountedAmounts = Record<CartProductSku, number>;
type CouponProductDiscounts = Record<DiscountId, ProductDiscountedAmounts>;
type PromoDiscountTotal = Record<
  PromoCodeId,
  {
    discounts: CouponProductDiscounts;
    couponCode: string;
    description: string;
    couponCodeId: string;
    couponCodeDisplayText: string;
    totalSavings: number;
  }
>;

const calculateCartDiscountAmounts = ({
  appliedDiscounts: appliedDiscountsIds,
  discounts,
  coupons,
  cartProducts,
  totals,
}: {
  appliedDiscounts: string[];
  discounts: Array<Discount>;
  coupons: Array<Coupon>;
  cartProducts: Record<string, CartProduct>;
  totals: any;
}): {
  breakdown: {
    discounts: CouponProductDiscounts;
    couponCode: string;
    description: string;
    couponCodeId: string;
    couponCodeDisplayText: string;
    totalSavings: number;
  }[];
  remove: Array<string>;
} => {
  const autoAppliedDiscounts = appliedDiscountsIds
    .map(discountId => {
      const discountApplied = discounts.find(discount => {
        return discount.id === discountId;
      });
      if (discountApplied) {
        return discountApplied;
      }
      return null;
    })
    .filter(
      discount =>
        discount !== null && discount.activateCondition !== 'couponCode'
    ) as Array<Discount>;

  const allDiscounts = autoAppliedDiscounts.map(discount => discount.id);

  // Map over the cartProducts and grab the discounts associated with the discount in the discountMap
  const promoDiscountTotal: PromoDiscountTotal = {};

  const remove: Array<string> = [];

  coupons.forEach(coupon => {
    if (coupon?.discounts?.length === 0) return;
    const couponDiscounts = coupon.discounts
      ?.filter(discount => appliedDiscountsIds.includes(discount.id))
      .map(couponDiscount => {
        const discountApplied = discounts.find(discount => {
          return discount.id === couponDiscount.id;
        });
        if (discountApplied && !allDiscounts.includes(discountApplied.id)) {
          allDiscounts.push(discountApplied.id);
          return discountApplied;
        }
        return null;
      })
      .filter(discount => discount !== null) as Array<Discount>;

    const relatedDiscounts = extractRelatedDiscounts({
      discounts: couponDiscounts,
      cartProducts,
      totals,
    });

    const totalSavings = Object.values(relatedDiscounts).reduce(
      (sum = 0, productDiscount) => {
        Object.values(productDiscount).forEach(
          productDiscount =>
            (sum = Math.floor((sum + productDiscount) * 100) / 100)
        );
        return sum;
      },
      0
    );

    promoDiscountTotal[coupon.id] = {
      couponCode: coupon.couponCode,
      description:
        (coupon?.description?.length ?? 0) > 0
          ? coupon.description ?? ''
          : couponDiscounts?.length > 0
            ? generateDiscountDescription({
                discount: couponDiscounts[0],
                products: cartProducts,
              })
            : coupon?.description ?? '',
      couponCodeId: coupon.id,
      couponCodeDisplayText: coupon.couponDisplayText ?? '',
      discounts: relatedDiscounts,
      totalSavings,
    };

    if (couponDiscounts?.length === 0) {
      remove.push(coupon.couponCode);
    }
  });

  const couponApplied = Object.values(promoDiscountTotal);

  return { breakdown: couponApplied, remove };
};

const generateDiscountDescription = ({
  discount,
  products,
}: {
  discount: Discount;
  products: Record<string, CartProduct>;
}) => {
  const {
    discountType,
    restrictTo,
    appliesTo,
    maxQuantity,
    freeMonths,
    discountAmount,
  } = discount;

  const percent = discountType == 'percentage';

  const descriptionMap: { [key: string]: string } = {
    product: 'equipment',
    freeMonths: 'monitoring',
    order: 'order',
    total: 'order',
    '': '',
  };

  const offWhat =
    restrictTo.length === 1 && products
      ? products?.[restrictTo[0]]?.name || ''
      : descriptionMap[appliesTo];

  const isFree = percent && discountAmount === 100;
  const amountOff = `${
    percent ? `${discountAmount}%` : `$${discountAmount}`
  } off`;

  const description =
    freeMonths && freeMonths > 0 && restrictTo.length === 1
      ? `${freeMonths} month${freeMonths > 1 ? 's' : ''} of free monitoring`
      : `${
          isFree ? `${maxQuantity ? `${maxQuantity} ` : ''}free` : amountOff
        } ${offWhat}${(maxQuantity || 0) > 1 ? 's' : ''}`;

  return description;
};

const extractRelatedDiscounts = ({
  discounts,
  cartProducts,
  totals,
}: {
  discounts: Array<Discount>;
  cartProducts: Record<string, CartProduct>;
  totals: any;
}) => {
  const couponProductDiscounts: CouponProductDiscounts = {};

  discounts?.forEach(discount => {
    const productDiscountedAmounts: ProductDiscountedAmounts = { order: 0 };

    if (discount.appliesTo === 'order' && discount.discountType === 'fixed') {
      productDiscountedAmounts.order += discount.discountAmount;
    }

    if (
      discount.appliesTo === 'order' &&
      discount.discountType === 'percentage'
    ) {
      productDiscountedAmounts.order +=
        totals.totals.orderBaseTotal * (discount.discountAmount / 100);
    }

    const applicableProducts =
      discount.restrictTo?.length > 0
        ? discount.restrictTo
        : Object.keys(cartProducts);

    applicableProducts.forEach(sku => {
      const product = cartProducts[sku];

      if (!product) return;
      let totalDiscount = product.discountMap?.[discount.id] || 0;

      // Calculate Free Months of Monitoring
      if (['vm-pn', 'bm-pn'].includes(sku)) {
        const freeMonthsDiscount =
          Math.round(discount.freeMonths * product.grandTotal * 100) / 100;
        totalDiscount = freeMonthsDiscount;
      }

      if (totalDiscount > 0) {
        productDiscountedAmounts[sku] = totalDiscount;
      }
    });

    couponProductDiscounts[discount.id] = productDiscountedAmounts;
  });

  return couponProductDiscounts;
};

const generateDiscountBadge = (discount: Discount, product: ProductRecord) => {
  const { discountType, discountAmount, maxQuantity } = discount;
  const qty = maxQuantity ?? product?.qty ?? 0;
  if (discountType === 'percentage') {
    if (discountAmount === 100 && qty > 0) {
      return `${qty} Free`;
    }
    return `${discountAmount}% off`;
  }

  if (discountType === 'fixed') return `$${discountAmount} off`;
};
type DiscountBreakdown = ReturnType<
  typeof calculateCartDiscountAmounts
>['breakdown'];

const missingInitialProducts = ({
  products,
  cartItemIds,
  cartProducts,
}: {
  products: SavedCart['products'];
  cartItemIds: SavedCart['cartItemIds'];
  cartProducts: SavedCart['cartProducts'];
}) => {
  const missingProducts =
    !isUndefined(products['CPA-PANEL-345-1']) &&
    !isUndefined(products['CPA-HUB-345-1']) &&
    !isUndefined(products['CP-YS-23i-1']) &&
    !isUndefined(products['window-sticker-sm']) &&
    !isEmpty(products['CPA-PANEL-345-1']) &&
    !isEmpty(products['CPA-HUB-345-1']) &&
    !isEmpty(products['CP-YS-23i-1']) &&
    !isEmpty(products['window-sticker-sm']) &&
    (!cartItemIds.includes('CPA-PANEL-345-1') ||
      !cartItemIds.includes('CPA-HUB-345-1') ||
      !cartItemIds.includes('CP-YS-23i-1') ||
      !cartItemIds.includes('window-sticker-sm') ||
      (products?.['CPA-PANEL-345-1']?.qty || 1) < 1 ||
      products['CPA-HUB-345-1'].qty !== 1 ||
      products['CP-YS-23i-1'].qty !== 1 ||
      products['window-sticker-sm'].qty !== 3 ||
      isUndefined(cartProducts['CPA-PANEL-345-1']) ||
      isUndefined(cartProducts['CPA-HUB-345-1']) ||
      isUndefined(cartProducts['CP-YS-23i-1']) ||
      isUndefined(cartProducts['window-sticker-sm']) ||
      isEmpty(cartProducts['CPA-PANEL-345-1']) ||
      isEmpty(cartProducts['CPA-HUB-345-1']) ||
      isEmpty(cartProducts['CP-YS-23i-1']) ||
      isEmpty(cartProducts['window-sticker-sm']) ||
      cartProducts['CPA-PANEL-345-1'].qty < 1 ||
      cartProducts['CPA-HUB-345-1'].qty !== 1 ||
      cartProducts['CP-YS-23i-1'].qty !== 1 ||
      cartProducts['window-sticker-sm'].qty !== 3);

  return missingProducts;
};

export {
  calculateCartDiscountAmounts,
  DiscountBreakdown,
  generateDiscountBadge,
  missingInitialProducts,
};
