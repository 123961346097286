import service from '@customer/services/coveService/service';
import { PaymentInfo } from '../paymentInfo';

const updatePaymentInfo = async (
  {
    accountGuid,
    id,
    ...paymentInfo
  }: { accountGuid: string; id: number } & Partial<PaymentInfo>,
  { accessToken }: { accessToken: string }
) => {
  const response = service.patch(
    `/account/${accountGuid}/payment-info/${id}`,
    {
      ...paymentInfo,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default updatePaymentInfo;
