import React from 'react';
import { Debug, GlobalThemeProvider } from '@shared/react-ui-core';
import PageWrapper from '@customer/components/pageWrapper';
import { GatsbyButton, GatsbyLink } from '@customer/components/gatsby/Link';
import { StoreProvider } from 'easy-peasy';
import store from '@customer/state/store';
import { ProductsProvider } from '@shared/react-product';
import MultiProvider from './MultiProvider';
import { CustomerRouterProvider } from './router';
import AmplifyProvider from './amplify';
import PersistProvider from './persist';
import { LocalProvider } from './local';
import { SessionProvider } from './session';
import CustomerCartProvider from './cart';

const BaseProvider: React.FC = ({ children, ...props }) => {
  const apiEnv =
    process.env.VERCEL_ENV ||
    process.env.GATSBY_API_ENV ||
    process.env.NODE_ENV ||
    'development';

  const partnerId = apiEnv === 'production' ? '29748061' : '147738923';
  const datoToken = process.env.DATO_TOKEN || '7c8a71c5a466ac293a9f9f29233e78';
  const preview = apiEnv !== 'production';

  return (
    <MultiProvider
      providers={[
        // <PageWrapper {...props} />,
        <StoreProvider store={store} />,
        <LocalProvider />,
        <SessionProvider />,
        <AmplifyProvider />,
        <ProductsProvider
          partnerId={partnerId}
          datoToken={datoToken}
          preview={preview}
        />,
        <CustomerCartProvider partnerId={partnerId} />,
        <CustomerRouterProvider />,
        <GlobalThemeProvider
          overwriteTheme={{
            components: {
              MuiButtonBase: {
                defaultProps: {
                  LinkComponent: GatsbyButton,
                },
              },
              MuiLink: {
                defaultProps: {
                  component: GatsbyLink,
                },
              },
            },
          }}
        />,
        ...(false ? [<PersistProvider />] : []),
      ]}
    >
      {children}
      <Debug />
    </MultiProvider>
  );
};

export default BaseProvider;
