/* eslint-disable prettier/prettier */
import { Theme as MaterialUITheme } from '@mui/material';
import { createTheme, Shadows } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface PaletteOptions {
    type?: string;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
  interface TypeBackground {
    darkBlue?: string;
    gray?: string;
    gray2?: string;
    gray3?: string;
  }

  interface BreakpointsOptions {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  }

  interface TypeText {
    gray?: string;
  }

  interface TypeAction {
    activeOpacity?: number;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

export const spacing = [5, 15, 25, 35, 50, 75, 100, 150];

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#002334',
      light: '#008cff',
      dark: '#3722d3',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00b19a',
      light: '#3ebeb1',
      dark: '#00cdb9',
      contrastText: '#002334',
    },
    error: {
      main: '#db1f26',
      light: '#E6312D',
    },
    divider: '#e4ded6',
    background: {
      default: '#ffffff',
      darkBlue: '#002334',
      gray: '#e4ded6',
      gray2: '#515151',
      gray3: '#f2efeb',
    },
    text: {
      primary: '#002334',
      secondary: '#2C3138',
      gray: '#e4ded6',
    },
    success: {
      main: '#00b19a',
      light: '#3ebeb1',
      dark: '#00cdb9',
      contrastText: '#002334',
    },
    warning: {
      main: '#E6312D',
      light: '#ea5b5b',
      dark: '#793000',
    },
    action: {
      active: '#002334',
      activeOpacity: 1,
      hover: '#002334',
      hoverOpacity: 0.7,
      focus: '#002334',
      focusOpacity: 1,
      selected: '#002334',
      selectedOpacity: 1,
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Quincy CF';
          src: url('https://use.typekit.net/af/2187af/00000000000000007735fcf3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
              format('woff2'),
            url('https://use.typekit.net/af/2187af/00000000000000007735fcf3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
              format('woff'),
            url('https://use.typekit.net/af/2187af/00000000000000007735fcf3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
              format('opentype');
          font-display: auto;
          font-style: normal;
          font-weight: 700;
          font-stretch: normal;
        }

        body {
          font-family: 'Poppins', sans-serif;
        }

        .visually-hidden {
          position: absolute !important;
          height: 1px;
          width: 1px;
          overflow: hidden;
          clip: rect(1px 1px 1px 1px);
          /* IE6, IE7 */
          clip: rect(1px, 1px, 1px, 1px);
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: '#002334',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 'unset',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#002334',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#002334',
        },
      },
      variants: [
        {
          props: { variant: 'unstyled' },
          style: {},
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: spacing[1],
        },
      },
    },
  },
  shadows: [
    'none',
    ...Array(24).fill('0px 4px 6px rgba(0, 0, 0, 0.15)'),
  ] as Shadows,
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1440 },
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1440,
  },
  spacing,
};

export const helpers = {
  small: (style: string) =>
    `@media (min-width: ${themeOptions.breakpoints.sm}px) { ${style} }`,
  medium: (style: string) =>
    `@media (min-width: ${themeOptions.breakpoints.md}px) { ${style} }`,
  large: (style: string) =>
    `@media (min-width: ${themeOptions.breakpoints.lg}px) { ${style} }`,
  xlarge: (style: string) =>
    `@media (min-width: ${themeOptions.breakpoints.xl}px) { ${style} }`,
};

// These are helper function to make it easier to use the theme
export const UICoreColor = {
  tan: themeOptions.palette.background.gray3,
  darktan: themeOptions.palette.background.gray,
  dark: themeOptions.palette.background.darkBlue,
  error: themeOptions.palette.error.main,
  warning: themeOptions.palette.warning.main,
  success: themeOptions.palette.success.main,
  primary: themeOptions.palette.primary.main,
  purple: themeOptions.palette.primary.dark,
  secondary: themeOptions.palette.secondary.main,
  contrastText: themeOptions.palette.primary.contrastText,
  text: themeOptions.palette.text.primary,
  info: themeOptions.palette.primary.main,
  light: themeOptions.palette.background.default,
  action: themeOptions.palette.action.active,
  transparent: 'transparent',
  marquee: '#062D1F',
} as const;
export type UICoreColor = keyof typeof UICoreColor;

export const UICoreSize = {
  full: '100%',
  wide: '1440px',
  narrow: '1340px',
} as const;
export type UICoreSize = keyof typeof UICoreSize;

export const UICoreSpacing = {
  px5: 0,
  px15: 1,
  px25: 2,
  px35: 3,
  px50: 4,
  px75: 5,
  px100: 6,
  px150: 7,
} as const;
type ValueOf<T> = T[keyof T];
export type UICoreSpacing = ValueOf<typeof UICoreSpacing>;

export type UICoreThemeOptions = typeof themeOptions;
export type UICoreTheme = typeof themeOptions.palette;
export type UICoreThemeTypeBackground = typeof themeOptions.palette.background;
export const UICoreBreakpoint = themeOptions.breakpoints.values;
export type UICoreBreakpoint = keyof typeof themeOptions.breakpoints.values;
export type UICoreBreakpoints = typeof themeOptions.breakpoints;

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module '@emotion/react' {
  export interface Theme extends MaterialUITheme {}
}

declare module '@mui/material/styles' {
  interface Theme extends UICoreTheme {}
  interface TypeBackground extends UICoreThemeTypeBackground {}
  type Breakpoint = UICoreBreakpoint;
  interface Breakpoints extends UICoreBreakpoints {}
  interface ThemeOptions extends UICoreThemeOptions {}
}
export const theme = createTheme(themeOptions);
