import React, { forwardRef, PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import { Button as MuiButton, Link as MuiLink } from '@mui/material';

type CustomProps =
  | { to: string; href?: string }
  | { to?: string; href: string };
export const GatsbyButton = forwardRef<
  HTMLAnchorElement,
  CustomProps & PropsWithChildren
>(({ to, href, children, ...props }, ref) => {
  const link = to || href;
  if (link?.[0] === '/') {
    return (
      // @ts-ignore
      <Link
        to={`${link}${link.slice(-1) !== '/' ? '/' : ''}`}
        // @ts-ignore
        {...{ ...props, children }}
      />
    );
  }
  if (link?.slice(0, 3) === 'tel:')
    return (
      <a href={link} {...props} ref={ref}>
        {children}
      </a>
    );
  return (
    <MuiButton {...{ LinkComponent: 'a', ...props, to, href }}>
      {children}
    </MuiButton>
  );
});

export const GatsbyLink = forwardRef<
  HTMLAnchorElement,
  CustomProps & PropsWithChildren
>(({ to, href, children, ...props }, ref) => {
  const link = to || href;
  if (link?.[0] === '/') {
    return (
      // @ts-ignore
      <Link
        to={`${link}${link.slice(-1) !== '/' ? '/' : ''}`}
        // @ts-ignore
        {...{ ...props, children }}
      />
    );
  }
  if (link?.slice(0, 3) === 'tel:')
    return (
      <a href={link} {...props} ref={ref}>
        {children}
      </a>
    );
  return (
    <MuiLink {...{ component: 'a', ...props, to, href }}>{children}</MuiLink>
  );
});
