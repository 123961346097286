// For more info on <Toaster /> options: https://react-hot-toast.com/docs/toaster
// For more info on styling toast: https://react-hot-toast.com/docs/styling

import { ToasterProps } from 'react-hot-toast';

export const toasterConfig: ToasterProps['toastOptions'] = {
  error: {
    style: {
      border: '1px solid #E6312D',
      color: '#E6312D',
      maxWidth: 720,
      margin: '0 auto',
      opacity: 1,
      padding: 0,
      pointerEvents: 'none',
    },
  },
  duration: 5000,
  style: {
    maxWidth: 720,
    margin: '0 auto',
    opacity: 1,
    padding: 0,
    pointerEvents: 'none',
    border: '1px solid #00CDB8',
    borderRadius: '15px',
  },
};
