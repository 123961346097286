import service, { PandoResponse } from '../..';
import { MyAccount } from '../myAccount';

const postMyAccount = async (
  {
    accountGuid,
    data,
  }: {
    accountGuid: string;
    data: Partial<MyAccount>;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<PandoResponse<{}>>(`/api/postMyAccount`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accountguid: accountGuid,
    },
  });
  return response;
};

export default postMyAccount;
