import service from '@customer/services/coveService/service';
import { PaymentInfo } from '../paymentInfo';

export interface PaymentInfoPayload {
  paymentInfo: PaymentInfo[];
}

const getPaymentInfos = async (
  { accountGuid }: { accountGuid: string },
  {
    accessToken,
    limit = 100,
    offset = 0,
  }: { accessToken: string; limit?: number; offset?: number }
) => {
  const query = new URLSearchParams({ limit: `${limit}`, offset: `${offset}` });
  const response = service.get<PaymentInfoPayload>(
    `/account/${accountGuid}/payment-info/?${query}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getPaymentInfos;
