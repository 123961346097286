import { useStoreRehydrated } from 'easy-peasy';
import { useEffect, useMemo } from 'react';
import { useRouter } from '@shared/react-router';
import { useStoreActions, useStoreState } from './useStore';

export type LoginType = 'user' | 'one-time-user';

const useIsLoggedIn = ({
  redirectTo,
  redirectUrl,
  loginTypes = ['user'],
}: {
  redirectTo?: string;
  redirectUrl?: string;
  loginTypes?: LoginType[];
} = {}) => {
  const { navigate } = useRouter();

  const isRehydrated = useStoreRehydrated();
  const loading = useStoreState(state => state?.router?.loading);
  const userIsLoggedIn = useStoreState(state => state?.user?.isLoggedIn);
  const oneTimeUserIsLoggedIn = useStoreState(
    state => state?.oneTimeUser?.isLoggedIn
  );
  const isLoggedIn = useMemo(
    () =>
      loginTypes.reduce(
        (agg = false, loginType) =>
          (loginType === 'user' && userIsLoggedIn) ||
          (loginType === 'one-time-user' && oneTimeUserIsLoggedIn) ||
          agg,
        false
      ),
    [userIsLoggedIn, oneTimeUserIsLoggedIn]
  );

  const getAccounts = useStoreActions(
    actions => actions?.pando?.accounts?.initialFetch
  );

  useEffect(() => {
    (async () => {
      if (isRehydrated && isLoggedIn && loginTypes.includes('user')) {
        getAccounts?.();
      }
    })();
  }, [isRehydrated, isLoggedIn, getAccounts]);

  if (!isRehydrated) {
    return null;
  }

  if (!isLoggedIn && redirectTo && !loading) {
    const url = (function () {
      if (redirectUrl) return redirectUrl;
      if (typeof window === 'undefined') return '/';
      // @ts-ignore
      const url = new URL(window.location.href);
      return url.href.slice(url.origin.length);
    })();

    navigate?.(redirectTo, { state: { redirectUrl: url } });
  }

  return isLoggedIn;
};

export default useIsLoggedIn;
