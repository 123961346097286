/** @jsxImportSource @emotion/react */
import { PropTypes } from 'prop-types';
import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

const Dots = ({
  loading = true,
  success = false,
  error = false,
  darkDots = false,
  dotWidth = 10,
  dotGap = 5,
  dotCss = ``,
  dotColor,
  css: propsCss,
  ...props
}) => {
  const firstDot = useRef();
  const secondDot = useRef();
  const thirdDot = useRef();
  const theme = useTheme();
  const { primary, background } = theme.palette;
  const dotsColor = darkDots ? '#002334' : dotColor ? dotColor : primary.dark;

  const dots = css`
    display: ${loading || success || error ? 'grid' : 'none'};
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    position: relative;
    margin-top: -10px;
    ${propsCss || ''};

    .dot {
      position: absolute;
      display: block;
      width: ${`${dotWidth}px`};
      height: ${`${dotWidth}px`};
      background: ${darkDots ? primary.main : dotsColor};
      border-radius: 50%;
      transition: all 0.25s ease;
      ${dotCss};
    }
    .first {
      right: ${`${dotWidth + dotGap}px`};
    }
    .third {
      right: ${`-${dotWidth + dotGap}px`};
    }
  `;

  useEffect(() => {
    const dotSpace = dotWidth + dotGap;
    const animateUpDown = (e, i) => {
      if (!e?.style) return;
      if (!error && !success) {
        e.style.left = `${dotSpace * -1 + dotSpace * i}px`;
        e.style.background = `${darkDots ? primary.main : dotsColor}`;
        let direction = 'up';
        return setInterval(() => {
          if (direction === 'up') {
            e.style.transform = 'translateY(5px)';
            direction = 'down';
          } else {
            e.style.transform = 'translateY(-5px)';
            direction = 'up';
          }
        }, 400);
      }
    };

    const animateComplete = e => {
      if (!e.style) return;
      e.style.left = '15px';
      e.style.transform = 'translateY(0)';
      e.style.background = `${theme.palette.success.dark}`;
    };

    const animateError = e => {
      if (!e.style) return;
      e.style.left = '15px';
      e.style.transform = 'translateY(0)';
      e.style.background = `${theme.palette.error.light}`;
    };

    let firstInterval;
    let secondInterval;
    let thirdInterval;

    if (loading) {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
      firstInterval = animateUpDown(firstDot.current, 0);
      setTimeout(() => {
        secondInterval = animateUpDown(secondDot.current, 1);
      }, 200);
      setTimeout(() => {
        thirdInterval = animateUpDown(thirdDot.current, 2);
      }, 400);
    }
    if (success) {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
      animateComplete(firstDot.current);
      animateComplete(secondDot.current);
      animateComplete(thirdDot.current);
    }
    if (error) {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
      animateError(firstDot.current);
      animateError(secondDot.current);
      animateError(thirdDot.current);
    }

    return () => {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
    };
  }, [loading, success, error, darkDots]);

  return (
    <div css={dots} {...props}>
      <span className="dot first" ref={firstDot} />
      <span className="dot second" ref={secondDot} />
      <span className="dot third" ref={thirdDot} />
    </div>
  );
};

export default Dots;

Dots.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  darkDots: PropTypes.bool,
  dotCss: PropTypes.any,
  css: PropTypes.any,
};
