import service from '@customer/services/coveService/service';

interface InteractiveProvider {
  accountGuid: string;
  interactiveProvider: string;
  panelType: string;
}

export interface CoveAccountInfo extends Array<InteractiveProvider> {}

const getCoveAccountInfo = async (
  { accountGuid }: { accountGuid: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<CoveAccountInfo>(
    `/account/${accountGuid}/account-info`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getCoveAccountInfo;
