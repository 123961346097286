import { action, Action } from 'easy-peasy';

export interface ActiveIdModel {
  currentId: string | null;
  setCurrentId: Action<ActiveIdModel, string>;
}
  
const activeId: ActiveIdModel = {
  currentId: null,
  setCurrentId: action((state, payload) => {
    localStorage.setItem('currentId', payload);
    state.currentId = payload;
  }),
};

export default activeId;