import service from '..';

const createOrderVerbalPassword = async (
  {
    orderNumber,
    verbalPassword,
  }: {
    orderNumber: string;
    verbalPassword: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.put<{ message: string }>(
    `/order/${orderNumber}/verbal-password`,
    {
      verbalPassword,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default createOrderVerbalPassword;
