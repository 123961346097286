import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import {
  AccountMonitoringPlan,
  getAccountMonitoringPlan,
} from '@customer/services/coveService';
import { persist, ThunkOn, thunkOn } from 'easy-peasy';
import { StoreModel } from '@customer/state/store/model';

export type Token = Promise<string> | null;

export interface AccountMonitoringPlanFetchPayload {
  accountsId?: string;
}
export interface AccountMonitoringPlanModel
  extends DataModel<
    AccountMonitoringPlan,
    any,
    AccountMonitoringPlanFetchPayload
  > {
  onAccountsSetCurrentId: ThunkOn<AccountMonitoringPlanModel, any, StoreModel>;
}

const monitoringPlan = persist<AccountMonitoringPlanModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId =
          payload?.accountsId || storeState?.activeId?.currentId || localStorage.getItem('currentId');
        const oneTimeUserToken = await storeActions.oneTimeUser.getToken();
        if (!oneTimeUserToken && currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          (oneTimeUserToken && storeState?.oneTimeUser?.payload?.accountGuid) ||
          (currentId &&
            storeState?.pando?.accounts?.data?.[currentId || '']?.AcctId);

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken =
          oneTimeUserToken || (await storeActions.pando.getToken());
        if (accessToken == null) {
          console.error(`missing accessToken`);
          return [];
        }
        const response = await getAccountMonitoringPlan(
          { accountGuid },
          { accessToken }
        );

        return [response?.data];
      },
      {
        idKey: 'accountId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.resetMemorizeInitialFetch();
          actions.initialFetch();
        }
      }
    ),
  },
  { deny: ['error'] }
);

export default monitoringPlan;
