import React, {
  useMemo,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { localStore } from '@shared/storage-js';

export interface Local {
  items: Record<string, string>;
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => void;
}

export const LocalContext: React.Context<Local> = createContext({} as Local);

export const LocalProvider: React.FC<{}> = ({ children }) => {
  const [items, setItems] = useState<Record<string, string>>({});

  const getItem = useCallback(
    (key: string) => {
      localStore.getItem();
      return items[key];
    },
    [items]
  );

  const setItem = useCallback(
    (key: string, value: string) => {},
    [items, setItems]
  );

  const value = useMemo<Local>(() => {
    return {
      items,
      getItem,
      setItem,
    };
  }, [items, getItem, setItem]);

  return (
    <LocalContext.Provider value={value}>{children}</LocalContext.Provider>
  );
};

export const useLocal = () => {
  const local = useContext(LocalContext);

  return local;
};
