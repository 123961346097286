/**
 * @param {string | number | undefined} value
 */
export const formatPhoneNumberDots = value => {
  if (!value) return '';

  const parsedValue = `${value}`.replace(/[^0-9]/g, '');

  const block1 = parsedValue.substring(0, 3);
  const block2 = parsedValue.substring(3, 6);
  const block3 = parsedValue.substring(6, 10);

  const formattedValue = [block1, block2, block3].filter(b => b).join('.');

  return formattedValue;
};
