import service from '@customer/services/coveService';

const resendCustomerPortalSetupEmail = async ({
  email,
  userPoolId,
}: {
  email: string;
  userPoolId: string;
}) => {
  const response = service.post<{
    data: string;
  }>(`/auth/resend-setup-email`, {
    email,
    userPoolId,
  });
  return response;
};

export default resendCustomerPortalSetupEmail;
