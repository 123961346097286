import service from '..';

export interface EquipmentBalance {
  equipmentBalance: number | null;
  paymentEndDate: string | null;
}

const getEquipmentBalance = async (
  { accountGuid }: { accountGuid: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<EquipmentBalance>(
    `/account/${accountGuid}/equipmentBalance`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getEquipmentBalance;
