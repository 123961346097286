import { DeliveryInfo, getDeliveryInfo } from '@customer/services/pandoService';
import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import { StoreModel } from '@customer/state/store/model';
import { persist, ThunkOn, thunkOn } from 'easy-peasy';

export interface DeliveryInfoFetchPayload {
  orderStatus?: string;
}

export interface DeliveryInfoModel
  extends DataModel<DeliveryInfo, any, DeliveryInfoFetchPayload> {
  onAccountsSetCurrentId: ThunkOn<DeliveryInfoModel, any, StoreModel>;
}

const deliveryInfo = persist<DeliveryInfoModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const { orderStatus } = payload || {};
        const state = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId = storeState?.activeId?.currentId || localStorage.getItem('currentId');
        if (currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken = await state.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }

        const response = await getDeliveryInfo(
          { accountGuid, orderStatus },
          { accessToken }
        );

        return response?.data?.Data;
      },
      {
        idKey: 'OrderId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.reset();
          actions.initialFetch();
        }
      }
    ),
  },
  { deny: ['error'] }
);
export default deliveryInfo;
