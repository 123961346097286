import service, { PandoResponse } from '..';

export interface PaymentHistoryRecord {
  Amount: number;
  IsSuccessful: boolean;
  LastFour: string;
  PaymentDate: string;
  PaymentHistoryItemId: string;
  Type: string;
  PromotionalCreditType: string;
}

export interface PaymentHistory {
  PaymentHistories: PaymentHistoryRecord[];
  TotalRecords: number;
}

const getPaymentHistory = async (
  {
    accountGuid,
    skip,
    limit,
  }: {
    accountGuid: string;
    skip?: number;
    limit?: number;
  },
  { accessToken }: { accessToken: string }
) => {
  const query = new URLSearchParams({
    ...(skip ? { skip: `${skip}` } : {}),
    ...(limit ? { limit: `${limit}` } : {}),
  }).toString();
  const response = service.get<PandoResponse<PaymentHistory>>(
    `/api/getPaymentHistory`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accountguid: accountGuid,
        query,
      },
    }
  );
  return response;
};

export default getPaymentHistory;
