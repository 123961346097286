import { localStore } from '@shared/storage-js';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import memoize from 'lodash/memoize';
import React, { FC, useEffect, useState } from 'react';

export interface Product {
  name: string;
  sku: string;
  createdAt: string;
  pandoId: number;
  productType: string;
  price: number;
  salePrice: number;
  payLaterEligible: boolean;
  description: string;
  featureList: { description: string }[];
  maximumSensorCountOnSite: number;
  recommendation: string;
  floorplanImage: { url: string };
  primaryImage: { url: string };
  category: { id: string; name: string; categoryId: string }[];
  lowInventoryIcon: boolean;
  outOfStock: boolean;
  outOfStockAvailabilityText: string;
  position: number;
  dimensionImage: { url: string };
}

const getProducts = memoize(
  async (
    {
      partnerId,
    }: {
      partnerId: string;
    },
    {
      datoToken,
      preview = false,
    }: {
      datoToken: string;
      preview?: boolean;
    }
  ) => {
    const response = await fetch(
      `https://graphql.datocms.com/${preview ? 'preview/' : ''}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${datoToken}`,
        },
        body: JSON.stringify({
          query: /* GraphQL */ `{
          partner(filter: {partnerId: {eq: "${partnerId}"}}) {
            productsList {
              ...productFields
            }
            plansList {
              ...productFields
            }
          }
        }

        fragment productFields on ProductRecord {
          name
          sku
          createdAt
          pandoId
          productType
          price
          salePrice
          payLaterEligible
          description
          featureList {
            description
          }
          maximumSensorCountOnSite
          recommendation
          floorplanImage {
            url
          }
          primaryImage {
            url
          }
          category {
            id
            name
            categoryId
          }
          lowInventoryIcon
          outOfStock
          outOfStockAvailabilityText
          position
          dimensionImage {
            url
          }
        }
        `,
        }),
      }
    );

    const data = (await response.json()) as {
      data: { partner: { productsList: Product[]; plansList: Product[] } };
    };

    return data;
  }
);

const getPersistedProducts = (): Record<string, Product> => {
  try {
    return JSON.parse(localStore.getItem('cove/products') || '{}');
  } catch (error) {
    console.error(error);
    return {};
  }
};

const getPersistedCart = (): {
  products?: Record<string, Product>;
} => {
  try {
    return JSON.parse(localStore.getItem('coveCart') || '{}');
  } catch (error) {
    console.error(error);
    return {};
  }
};

export type Products = Record<string, Product>;

const ProductContext = React.createContext<Products>(getPersistedProducts());

export const ProductsProvider: FC<
  React.PropsWithChildren & {
    partnerId: string;
    datoToken: string;
    preview: boolean;
  }
> = ({ partnerId, datoToken, preview, ...props }) => {
  const [products, setProducts] = useState(getPersistedProducts());
  useEffect(() => {
    const getSetProducts = async () => {
      if (!partnerId || !datoToken) return;

      const data = await getProducts({ partnerId }, { datoToken, preview });

      const newProducts = keyBy(
        [
          ...(data?.data?.partner?.productsList || []),
          ...(data?.data?.partner?.plansList || []),
        ],
        'sku'
      );
      const persistedProducts = getPersistedProducts() || {};
      const persistedCart = getPersistedCart() || {};
      const cartProducts = persistedCart?.products || {};
      const finalProducts = merge(
        cartProducts,
        Object.keys(newProducts)?.length ? newProducts : persistedProducts
      );
      localStore.setItem('cove/products', JSON.stringify(finalProducts));
      setProducts(finalProducts);
    };
    getSetProducts();
  }, [partnerId, datoToken, preview]);

  return <ProductContext.Provider value={products} {...props} />;
};

export const useProducts = () => {
  const context = React.useContext(ProductContext);
  if (context === undefined) {
    throw new Error(`useProducts must be used within a ProductsProvider`);
  }
  return context;
};

export interface WithProductsProps {
  products: Products;
}

export const withProducts =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P & WithProductsProps) => {
    return <Component {...props} products={useProducts()} />;
  };
