import service, { PandoResponse } from '../..';
import { EmergencyContact } from '../emergencyContact';

const postMyAccount = async (
  {
    accountGuid,
    data: { FirstName, LastName, Phone, SequenceNumber },
  }: {
    accountGuid: string;
    data: Partial<EmergencyContact>;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<PandoResponse<{}>>(
    `/api/addEmergencyContact`,
    { FirstName, LastName, Phone, SequenceNumber, accountGuid },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default postMyAccount;
