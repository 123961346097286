import React, { useEffect, useCallback, PropsWithChildren } from 'react';
import { Link, navigate } from 'gatsby';
import { globalHistory } from '@reach/router';
import { useStoreActions, useStoreDispatch } from '@customer/hooks/useStore';
import { RouterNavigate, RouterProvider } from '@shared/react-router';

export const CustomerRouterProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useStoreDispatch();
  const listener = useStoreActions(state => state.router.listener);
  useEffect(() => {
    return globalHistory.listen(listener);
  }, [dispatch]);
  const setLoading = useStoreActions(actions => actions.router.setLoading);
  const setNavigatedState = useStoreActions(
    actions => actions.router.setNavigatedState
  );

  const handleNavigate: RouterNavigate = useCallback(
    async (to, options) => {
      setLoading(true);
      try {
        setNavigatedState(options?.state || {});
        await navigate(to, options);
      } catch (error) {
        console.error(error);
      } finally {
        const checkLoading = () =>
          new Promise(resolve => {
            setTimeout(() => {
              if (typeof window === 'undefined') {
                return resolve({});
              }
              if (`${to}`?.startsWith?.(window.location.pathname)) {
                setLoading(false);
                return resolve({});
              }
              return resolve(checkLoading());
            }, 100);
          });
        checkLoading();
      }
    },
    [setNavigatedState]
  );

  return (
    <RouterProvider navigate={handleNavigate} Link={Link}>
      {children}
    </RouterProvider>
  );
};
