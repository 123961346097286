import {
  action,
  Action,
  computed,
  Computed,
  thunk,
  Thunk,
  thunkOn,
  ThunkOn,
  Helpers,
} from 'easy-peasy';
import memoize from 'lodash/memoize';
import { StoreModel } from '../model';

export interface ObjectWithId {
  id: string;
}

export type FetchError = Error | any;

export type DataModel<DataItem, Injections, FetchPayload = {}> = {
  data: { [key: string]: DataItem & ObjectWithId } | null;
  ids: Computed<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    string[]
  >;
  resetData: Action<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>
  >;
  currentId: string | null;
  setCurrentId: Action<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    string | null
  >;
  initialFetch: Thunk<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    FetchPayload | undefined,
    Injections,
    StoreModel
  >;
  isLoading: boolean;
  setIsLoading: Action<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    boolean
  >;
  fetch: Thunk<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    FetchPayload | undefined,
    Injections,
    StoreModel
  >;
  fetched: Action<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    Partial<DataItem & ObjectWithId>[]
  >;
  addAbortController: Action<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    { controller: AbortController; type?: string }
  >;
  removeAbortController: Action<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    AbortController
  >;
  abort: Thunk<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    string,
    Injections,
    StoreModel
  >;
  error: FetchError | null;
  setError: Action<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    FetchError | null
  >;
  resetMemorizeInitialFetch: Thunk<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    undefined,
    Injections,
    StoreModel
  >;
  onRouterListener: ThunkOn<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    Injections,
    StoreModel
  >;
  reset: Thunk<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    undefined,
    Injections,
    StoreModel
  >;
  onStoreReset: ThunkOn<
    DataModel<DataItem & ObjectWithId, Injections, FetchPayload>,
    Injections,
    StoreModel
  >;
};

export type DataModelEndpoint<Item, Injections, FetchPayload> = (
  params: FetchPayload | undefined,
  helpers: Helpers<
    DataModel<Item, Injections, FetchPayload>,
    StoreModel,
    Injections
  >,
  controller: AbortController
) => Promise<Partial<Item & ObjectWithId>[]> & { controller?: AbortController };

export interface DataModelOptions {
  idKey?: string;
}

export const dataModel = <Item, Injections, FetchPayload = {}>(
  endpoint: DataModelEndpoint<Item, Injections, FetchPayload>,
  { idKey }: DataModelOptions = {}
): DataModel<Item, Injections, FetchPayload> => {
  const memoizeInitialFetch = memoize((payload, callback) => callback(payload));
  const abortControllers = new Map<AbortController, string>();
  return {
    data: null,
    resetData: action((state, payload) => {
      state.data = null;
    }),
    ids: computed([state => state.data, state => state.data], data =>
      Object.keys(data || {})
    ),
    currentId: null,
    setCurrentId: action((state, id) => {
      state.currentId = id;
      console.log('currentId', id);
    }),
    fetched: action((state, items) => {
      state.currentId = null;
      items.forEach((item, index) => {
        // @ts-ignore
        const id: string = `${(idKey && item[idKey]) || item.id}`;
        if (index === 0) {
          state.currentId = id;
        }
        state.data = state.data || {};
        state.data[id] = {
          ...item,
          id,
        } as Item & ObjectWithId;
      });
      state.data = state.data ? { ...state.data } : state.data;
    }),
    addAbortController: action((state, { controller, type = 'fetch' }) => {
      abortControllers.set(controller, type);
    }),
    removeAbortController: action((state, controller) => {
      abortControllers.delete(controller);
    }),
    abort: thunk(async (actions, payload, helpers) => {
      abortControllers.forEach((type, controller) => {
        controller.abort(payload);
        actions.removeAbortController(controller);
      });
    }),
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),
    fetch: thunk(async (actions, payload, helpers) => {
      actions.setError(null);
      const controller = new AbortController();
      actions.addAbortController({ controller });
      try {
        actions.setIsLoading(true);
        const data = await endpoint(payload, helpers, controller);
        actions.removeAbortController(controller);
        controller.signal.throwIfAborted();
        actions.fetched(data);
      } catch (error) {
        if (error instanceof DOMException && error.name == 'AbortError') {
          throw error;
        }
        actions.setError(error);
      } finally {
        actions.setIsLoading(false);
      }
    }),
    initialFetch: thunk(async (actions, payload, helpers) => {
      return await new Promise(resolve => {
        memoizeInitialFetch(payload, () => {
          resolve(actions.fetch(payload as any));
        });
      });
    }),
    error: null,
    setError: action((state, payload) => {
      state.error = payload;
    }),
    resetMemorizeInitialFetch: thunk((actions, payload, helpers) => {
      memoizeInitialFetch?.cache?.clear?.();
    }),
    onRouterListener: thunkOn(
      (actions, storeActions) => storeActions.router.listener,
      (actions, target, helpers) => {
        console.debug('resetMemorizeInitialFetch', { actions, target });
        actions.resetMemorizeInitialFetch();
      }
    ),
    reset: thunk(async (actions, payload, helpers) => {
      await actions.abort('reset');
      actions.setCurrentId(null);
      actions.resetData();
      actions.setError(null);
      actions.resetMemorizeInitialFetch();
    }),
    onStoreReset: thunkOn(
      (actions, storeActions) => storeActions.reset,
      async (actions, target, helpers) => {
        actions.reset();
      }
    ),
  };
};
