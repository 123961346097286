import { CssBaseline, createTheme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme } from '../theme';

function GlobalThemeProvider({ overwriteTheme, children }) {
  return (
    <MuiThemeProvider theme={createTheme(theme, overwriteTheme)}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

export { GlobalThemeProvider };
