import service from '../service';

export interface AccountReferral {
  firstName: string;
  lastName: string;
  rewardType: string | null;
  createdAt: string;
  updatedAt: string | null;
  voidedAt: string | null;
  redeemedAt: string | null;
}

const getAccountReferrals = async (
  { accountGuid }: { accountGuid: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<AccountReferral[]>(
    `/account/${accountGuid}/referrals`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getAccountReferrals;
