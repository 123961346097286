import Axios from 'axios';
import keyBy from 'lodash/keyBy';

const apiEnv =
  (typeof window !== 'undefined' && process.env.GATSBY_API_ENV) ||
  'development';

const token = '7c8a71c5a466ac293a9f9f29233e78';

/**
 *
 * @param {object=} params
 * @param {string=} params.partnerId
 */
const datoLoad = async ({
  partnerId = apiEnv !== 'production' ? '39770523' : '6400414',
} = {}) =>
  Axios.post(
    `https://graphql.datocms.com/${apiEnv !== 'production' ? '' : ''}`,
    {
      query: /* GraphQL */ `{
      partner(filter: {partnerId: {eq: "${partnerId}"}}) {
        productsList {
          ...productFields
        }
        plansList {
          ...productFields
        }
      }
      partnerSalesConfig(filter: {partner: {eq: "${partnerId}"}}) {
        discountsList {
          id
          active
          activateCondition
          appliesTo
          conditionProductsQuantifier
          conditionProducts {
            sku
          }
          description
          minimumOrderTotal
          minimumOrderTotalAfterDiscount
          freeMonths
          exclusiveDiscount
          maxQuantity
          discountType
          discountAmount
          removeFromBase
          removeFromSalePrice
          priority
          restrictTo {
            id
            sku
          }
          additionalDiscounts {
            id
          }
          applyAfterDiscounts {
            id
          }
          overrideDiscounts {
            id
          }
        }
      }

      allProductCategories(first: 100) {
        id
        name
      }
      payLater {
        maximum
        minimum
        restrictTo {
          id
          sku
        }
      }
    }

    fragment productFields on ProductRecord {
      name
      sku
      createdAt
      pandoId
      productType
      price
      salePrice
      payLaterEligible
      description
      featureList {
        description
      }
      maximumSensorCountOnSite
      recommendation
      floorplanImage {
        url
      }
      primaryImage {
        url
      }
      category {
        name
        id
      }
      lowInventoryIcon
      outOfStock
      outOfStockAvailabilityText
      position
    }
    `,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: 5000,
    }
  )
    .then(({ data: { data }, errors }) => {
      data.partnerSalesConfig?.discountsList?.map?.(discount => {
        discount.restrictTo = discount.restrictTo.map(product => product.sku);
        discount.conditionProducts = discount.conditionProducts.map(
          product => product.sku
        );
        return discount;
      });
      data.payLater.restrictTo = data.payLater
        ? data.payLater.restrictTo.map(product => product.sku)
        : [];
      return {
        products: keyBy(
          [...data.partner.productsList, ...data.partner.plansList],
          'sku'
        ),
        discounts: data?.partnerSalesConfig?.discountsList,
        payLater: data.payLater,
      };
    })
    .catch(error => {
      console.error(error);
      return { error };
    });

export default datoLoad;
