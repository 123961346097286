import config from '@customer/utils/config';
import service, { PandoResponse } from '..';

export interface LinkedAccount {
  AccountNumber: number;
  AcctId: string;
  FirstName: string;
  LastName: string;
}

const getLinkedAccounts = async ({ accessToken }: { accessToken: string }) => {
  const response = service.get<PandoResponse<LinkedAccount[]>>(
    `/api/getLinkedAccounts`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getLinkedAccounts;
