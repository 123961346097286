import jwt_decode from 'jwt-decode';
import {
  action,
  Action,
  persist,
  thunk,
  Thunk,
  thunkOn,
  ThunkOn,
} from 'easy-peasy';
import debounce from 'lodash/debounce';
import type { StoreModel } from '@customer/state/store/model';
import { getOnBehalfOfPandoToken } from '@customer/services/coveService';
import config from '@customer/utils/config';
import type { PandoModel } from './model';
import deliveryInfo from './deliveryInfo';
import myAccount from './myAccount';
import accounts from './accounts';
import billing from './billing';
import paymentHistory from './paymentHistory';
import paymentMethods from './paymentMethods';
import paymentOptions from './paymentOptions';

export type Token = Promise<string> | string;
export type UserSub = string;

export interface PandoModelRoot {
  token: Token | null;
  setToken: Action<PandoModel, Token | null>;
  getToken: Thunk<PandoModel, undefined, any, StoreModel, Token | undefined>;
  userSub: UserSub | null;
  getUserSub: Action<PandoModel, UserSub | null>;
  setUserSub: Action<PandoModel, UserSub | null>;
  reset: Thunk<PandoModel, undefined, any, StoreModel>;
  onStoreReset: ThunkOn<PandoModel, any, StoreModel>;
}

const pando = persist<PandoModel>(
  {
    token: null,
    userSub: null,
    setToken: action((state, payload) => {
      state.token = payload;
    }),
    getToken: thunk(
      debounce(
        async (actions, payload, helpers) => {
          const state = helpers.getState();
          const storeActions = helpers.getStoreActions();

          const { accessToken, idToken } = await storeActions.user.getTokens();
          if (!idToken || !accessToken) {
            throw new Error('not logged in');
          }

          const userTokenPayload = jwt_decode<{
            sub: string;
            exp?: number;
          }>(await accessToken);

          if (state.token !== null) {
            try {
              const userSub = actions.getUserSub();
              const tokenPayload = jwt_decode<{ exp?: number }>(
                await state.token
              );

              if (
                userSub === userTokenPayload.sub &&
                tokenPayload?.exp &&
                tokenPayload?.exp * 1000 > Date.now()
              ) {
                return state.token;
              }
            } catch (error) {
              console.log('getting new token');
              console.debug(state.token, error, state);
            }
          }

          const clientId = config.COGNITO_USER_POOL_WEB_CLIENT_ID;
          const pandoClientId = config.PANDO_API_CLIENT_ID;
          if (!clientId || !pandoClientId) {
            throw new Error('miss configured client ids');
          }

          const token = (async () => {
            try {
              const response = await getOnBehalfOfPandoToken(
                {
                  idToken,
                  clientId,
                  pandoClientId,
                },
                {
                  accessToken,
                }
              );
              const token = response.data.access_token;
              return token;
            } catch (error) {
              console.error(error);
              return null;
            }
          })();
          actions.setToken(token);
          actions.setUserSub(userTokenPayload.sub);
          new Promise(async resolve => {
            actions.setToken(await token);
            actions.setUserSub(userTokenPayload.sub);
          });
          return await token;
        },
        500,
        { leading: true, trailing: false }
      )
    ),
    getUserSub: action((state, payload) => {
      state.userSub = payload;
    }),
    setUserSub: action((state, payload) => {
      state.userSub = payload;
    }),
    reset: thunk(async (actions, payload, helpers) => {
      actions.setToken(null);
      actions.setUserSub(null);
    }),
    onStoreReset: thunkOn(
      (actions, storeActions) => storeActions.reset,
      async (actions, target, helpers) => {
        actions.reset();
      }
    ),
    accounts,
    deliveryInfo,
    myAccount,
    billing,
    paymentHistory,
    paymentMethods,
    paymentOptions,
  },
  {
    allow: ['token', 'userSub'],
  }
);
export default pando;
