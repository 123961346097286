/** @jsxImportSource @emotion/react */
import { localStore } from '@shared/storage-js';
import {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import coveCart, { defaultSaveCart, log } from './coveCart';
import { DatoData } from './schemas';
import { initialCart } from './helpers';

const uuidV4Regex =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

const CartContext =
  createContext<Awaited<ReturnType<typeof coveCart>>>(initialCart);

export const useCart = () => {
  const context = useContext(CartContext);
  if (typeof context === 'undefined') {
    throw new Error(`useCart must be used within a CartProvider`);
  }
  return context;
};

export type Cart = ReturnType<typeof useCart>;

interface WithCartProps {
  cart: Cart;
}
export const withCart = <P extends object>(
  Component: ComponentType<P & WithCartProps>
) => {
  return function WrappedComponent(props: P) {
    const cart = useCart();
    return <Component {...props} cart={cart} />;
  };
};

type CartProviderProps = {
  apiV4Url: string;
  datoData: Promise<DatoData>;
  prefixLocalStorageKey?: string;
  saveCart?: typeof defaultSaveCart;
  shippingMethod?: string;
  efTransactionId?: string;
};

export const CartProvider = ({
  apiV4Url,
  datoData,
  prefixLocalStorageKey,
  saveCart,
  shippingMethod,
  efTransactionId,
  ...props
}: CartProviderProps) => {
  const [cart, setCart] = useState<Cart>(initialCart);
  const [cartId, setCartId] = useState<string | null>(null);
  const [loadRemote, setLoadRemote] = useState<boolean>(false);
  const [salesRepId, setSalesRepId] = useState<string | null>(null);
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [partnerId, setPartnerId] = useState<string | null>(null);
  const [debugParam, setDebugParam] = useState<string>('');
  const [readyToInitializeCart, setReadyToInitializeCart] =
    useState<boolean>(false);
  const debug = debugParam === 'chucktest';

  const cartIdLocalStorageKey = `${prefixLocalStorageKey || ''}CART_ID`;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localCartId = localStore.getItem(cartIdLocalStorageKey);

      const urlParams = new URLSearchParams(window.location.search);
      const cartId =
        urlParams.get('cartId') || urlParams.get('cartid') || localCartId;
      const promoCode =
        urlParams.get('promoCode') || urlParams.get('promocode');
      const partnerId =
        urlParams.get('partnerId') || localStore.getItem('partnerId');
      const salesRepId = urlParams.get('salesRepId');
      const debugParam = urlParams.get('debug') || '';
      const resetCart = urlParams.get('resetCart') === 'true';
      const referralCode = urlParams.get('referralCode');

      if (
        cartId &&
        cartId !== '' &&
        (resetCart || salesRepId || localCartId !== cartId)
      ) {
        setLoadRemote(true);
      }

      if (!uuidV4Regex.test(cartId)) {
        const newCartId = uuid();
        setCartId(newCartId);

        if (debug) {
          log(`Creating new cart id: ${newCartId}`);
        }
      } else {
        setCartId(cartId);

        if (debug) {
          log(`Cart id set to: ${cartId}`);
        }
      }

      setPromoCode(promoCode);
      setPartnerId(partnerId);
      setSalesRepId(salesRepId);
      setDebugParam(debugParam);
      setReferralCode(referralCode);

      setReadyToInitializeCart(true);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && !!cartId) {
      localStore.setItem(cartIdLocalStorageKey, cartId);
    }
  }, [cartId]);

  useEffect(() => {
    // TODO: Remove once Acadia is done testing
    // BEGIN ACADIA GLOBAL CART EXPOSURE CODE
    // Added so Acadia could access cart globally with full updates
    // for split testing purposes on quiz-results page.
    // Can remove once these split test are done.
    if (typeof window !== 'undefined' && !!cartId) {
      window['COVE_CART'] = cart;
    }
    // END ACADIA GLOBAL CART EXPOSURE CODE
  }, [cart]);

  const initializeCart = useCallback(async () => {
    //@ts-ignore
    let cartSingleton = await coveCart({
      cartId,
      updateCallback: setCart,
      apiV4Url,
      loadRemote,
      salesRepId,
      referralCode,
      datoData,
      saveCart,
      shippingMethod,
      prefixLocalStorageKey,
      efTransactionId,
      debug,
    });
    setCart(cartSingleton);

    if (promoCode && cartSingleton.applyPromo) {
      await cartSingleton.applyPromo(
        promoCode.replaceAll('/', ''),
        promoCode.toUpperCase() === 'GIVE25'
      );
    } else if (cartSingleton.promoCode) {
      await cartSingleton.applyPromo(
        cartSingleton.promoCode.replaceAll('/', ''),
        !!cartSingleton?.isReferral
      );
    }
  }, [cartId, promoCode, partnerId, salesRepId, debugParam, referralCode]);

  useEffect(() => {
    if (readyToInitializeCart) {
      initializeCart();
      setReadyToInitializeCart(false);
    }
  }, [readyToInitializeCart, initializeCart]);

  const memoizedValue = useMemo(() => cart, [cart]);

  return <CartContext.Provider value={memoizedValue} {...props} />;
};
