/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { motion, AnimatePresence, HTMLMotionProps } from 'framer-motion';
import { PropsWithChildren, useId } from 'react';

const SmoothMount: React.FC<
  PropsWithChildren<
    HTMLMotionProps<'div'> & {
      show: boolean;
      height?: boolean;
      fade?: boolean;
      scale?: boolean;
      css?: SerializedStyles;
      initial?: boolean;
      mode?: 'sync' | 'wait' | 'popLayout';
      onExitComplete?: () => void;
      showOverflow?: boolean;
    }
  >
> = ({
  show,
  height = true,
  fade,
  scale,
  children,
  initial,
  mode,
  onExitComplete,
  showOverflow,
  ...props
}) => {
  const key = useId();
  return (
    <AnimatePresence
      initial={initial}
      mode={mode}
      onExitComplete={onExitComplete}
    >
      {show && (
        <motion.div
          key={key}
          initial={{
            height: height ? 0 : 'auto',
            opacity: fade ? 0 : 1,
            scale: scale ? 0 : 1,
          }}
          animate={{ height: 'auto', opacity: 1, scale: 1 }}
          exit={{
            height: height ? 0 : 'auto',
            opacity: fade ? 0 : 1,
            scale: scale ? 0 : 1,
          }}
          transition={{ ease: [0.5, 1, 0.5, 1] }}
          style={{
            width: '100%',
            overflow: showOverflow ? 'visible' : 'hidden',
          }}
          {...props}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SmoothMount;
