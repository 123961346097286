import service from '@customer/services/coveService';

const changeAccountMonitoringPlan = async (
  { accountGuid, plan }: { accountGuid: string; plan: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.put<{
    accountId: string;
    plan: string;
  }>(
    `/account/${accountGuid}/monitoringPlan`,
    {
      plan,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default changeAccountMonitoringPlan;
