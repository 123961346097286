import service from '../service';

const getAlarmCertificate = async (
  { accountGuid, accessToken }: { accountGuid: string, accessToken: string }
) => {
  const response = service.get(
    `/account/${accountGuid}/certificate`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getAlarmCertificate;
