import React, {
  useMemo,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { sessionStore } from '@shared/storage-js';

export interface Session {
  items: Record<string, string>;
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => void;
}

export const SessionContext: React.Context<Session> = createContext(
  {} as Session
);

export const SessionProvider: React.FC<{}> = ({ children }) => {
  const [items, setItems] = useState<Record<string, string>>({});

  const getItem = useCallback(
    (key: string) => {
      sessionStore.getItem();
      return items[key];
    },
    [items]
  );

  const setItem = useCallback(
    (key: string, value: string) => {},
    [items, setItems]
  );

  const value = useMemo<Session>(() => {
    return {
      items,
      getItem,
      setItem,
    };
  }, [items, getItem, setItem]);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const useSession = () => {
  const session = useContext(SessionContext);

  return session;
};
