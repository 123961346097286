import service, {
  PandoResponse,
} from '@customer/services/pandoService/service';

export interface PaymentMethod {
  AccountHolder: string;
  BillingAddress: {
    CountryCode: string;
    PostalCode: string;
    StateCode: string;
    Address1: string;
    Address2: string;
    City: string;
  };
  CardType: string;
  CardTypeName: string;
  DateAdded: string;
  ExpiryDate: string;
  IsExpired: false;
  IsExpiring: false;
  LastFour: string;
  PaymentInfoId: number;
  RemainingDays: number;
}

export interface PaymentMethods {
  CurrentPaymentMethod: PaymentMethod;
  OtherPaymentMethods: PaymentMethod[];
}

const getPaymentMethods = async (
  {
    accountGuid,
  }: {
    accountGuid: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<PandoResponse<PaymentMethods>>(
    `/api/getPaymentMethods`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accountguid: accountGuid,
      },
    }
  );
  return response;
};

export default getPaymentMethods;
