import service, { PandoResponse } from '..';

export interface Billing {
  BalanceStatus: string;
  CurrentBalance: number;
  CurrentTaxBalance: number;
  CurrentBillingDay: number;
  CurrentMonthlyAmount: number;
  DayOfWeek: string;
  LastBilledAmount: number;
  LastBillingDate: string;
  NextBillingDate: string;
  WeekNumber: string;
  LastPaymentDate: string;
  LastPaymentAmount: number;
  PrimaryAccountStatus: string;
  SecondaryAccountStatus: string;
  RemainingFreeMonths: number;
  NextPaymentDate: string;
}

const getBilling = async (
  {
    accountGuid,
  }: {
    accountGuid: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<PandoResponse<Billing>>(`/api/getBilling`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      AccountGuid: accountGuid,
    },
  });
  return response;
};

export default getBilling;
