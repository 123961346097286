import service, { PandoResponse } from '..';

export interface PaymentOption {
  BillingOptionId: string;
  FirstAmount: number;
  FirstDate: string;
  ProRateAmount: number;
  Rmr: number;
  SecondAmount: number;
  SecondDate: string;
  Text: string;
}

export interface PaymentOptions {
  BillingDayType: string;
  NextBillingDate: string;
  Options: PaymentOption[];
}

const getPaymentOptions = async (
  {
    billingDay,
    accountGuid,
  }: {
    billingDay: string;
    accountGuid: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<PandoResponse<PaymentOptions>>(
    `/api/getPaymentOptions`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accountguid: accountGuid,
        Billingday: billingDay,
      },
    }
  );
  return response;
};

export default getPaymentOptions;
