import service, {
  PandoResponse,
} from '@customer/services/pandoService/service';
import { MyAccount } from '../myAccount';

const getMyAccount = async (
  {
    accountGuid,
  }: {
    accountGuid: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<PandoResponse<MyAccount[]>>(`/api/getAccount`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accountGuid,
    },
  });
  return response;
};

export default getMyAccount;
