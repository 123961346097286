import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import { MyAccount, getMyAccount } from '@customer/services/pandoService';
import { persist, ThunkOn, thunkOn } from 'easy-peasy';
import { StoreModel } from '@customer/state/store/model';

export type Token = Promise<string> | null;

export interface MyAccountFetchPayload {
  accountsId?: string;
}
export interface MyAccountModel
  extends DataModel<
    MyAccount & { AcctId: string },
    any,
    MyAccountFetchPayload
  > {
  onAccountsSet: ThunkOn<MyAccountModel, any, StoreModel>;
}

const myAccount = persist<MyAccountModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const state = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId =
          payload?.accountsId || storeState?.activeId?.currentId || localStorage.getItem('currentId');
        if (currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken = await state.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }
        const response = await getMyAccount({ accountGuid }, { accessToken });

        return [{ ...response?.data?.Data, AcctId: currentId }];
      },
      {
        idKey: 'AcctId',
      }
    ),
    onAccountsSet: thunkOn(
      (actions, storeActions) => storeActions.pando.accounts.fetched,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        storeState.pando.accounts.ids.forEach(accountsId => {
          actions.fetch({ accountsId });
        });
      }
    ),
  },
  { deny: ['error'] }
);
export default myAccount;
