import service, { PandoResponse } from '..';

const linkAccount = async (
  {
    accountId,
    email,
    siteAddress,
    sitePassword,
  }: {
    accountId: number;
    email: string;
    siteAddress?: {
      address1: string;
      address2: string;
      city: string;
      stateCode: string;
      postalCode: string;
      countryCode: string;
    };
    sitePassword?: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const SiteAddress = siteAddress
    ? {
        Address1: siteAddress.address1,
        Address2: siteAddress.address2,
        City: siteAddress.city,
        StateCode: siteAddress.stateCode,
        PostalCode: siteAddress.postalCode,
        CountryCode: siteAddress.countryCode,
      }
    : undefined;
  const response = service.post<PandoResponse<unknown>>(
    `/api/linkAccount`,
    {
      AccountNumber: accountId,
      EmailAddress: email,
      SiteAddress,
      SitePassword: sitePassword,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default linkAccount;
