import service from '@customer/services/coveService/service';

export interface Cart {}

const placeCustomerOrder = async (
  {
    accountGuid,
    cartId,
    cartProducts,
    coveCredit,
    shippingMethod = 'Regular',
    promoCode,
    couponCodes,
    orderGrandTotal,
    paymentMethodId,
    shippingAddress,
  }: {
    accountGuid: string;
    cartId: string;
    cartProducts: {
      sku: string;
      qty: number;
    }[];
    coveCredit?: number;
    shippingMethod?: string;
    promoCode?: string;
    couponCodes?: string[];
    orderGrandTotal: string | number;
    paymentMethodId?: number;
    shippingAddress?: {
      address1: string;
      address2: string;
      city: string;
      stateCode: string;
      postalCode: string;
    };
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<Cart>(
    `/account/${accountGuid}/order`,
    {
      cartProducts,
      cartId,
      coveCredit,
      shippingMethod,
      promoCode,
      couponCodes,
      orderGrandTotal,
      paymentMethodId,
      shippingAddress,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default placeCustomerOrder;
