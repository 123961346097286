/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import 'promise-polyfill/src/polyfill';
import BaseProvider from '@customer/context/base';
import GatsbyBrowserStatus from '@customer/components/gatsby/BrowserStatus';
import configureAmplify from '@shared/react-auth/configureAmplify';
import config from './src/utils/config';
import Scripts from './scripts';
import { Toaster } from '@shared/react-ui-core';
import './src/styles/global.css';
import './src/styles/tailwind.css';

configureAmplify({
  region: config.COGNITO_REGION,
  userPoolId: config.COGNITO_USER_POOL_ID,
  userPoolWebClientId: config.COGNITO_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = (
  { element, ...restProps },
  ...args
) => {
  const env = process.env.NODE_ENV;
  return (
    <>
      <BaseProvider
        name="wrapRootElement"
        {...restProps}
        mode="browser"
        browser="true"
      >
        <Scripts env={env} />
        {element}
        <Toaster />
        <GatsbyBrowserStatus />
      </BaseProvider>
    </>
  );
};
