import service from '@customer/services/coveService/service';

export interface CoveCreditBalance {
  coveCreditBalance: number | null;
}

const getCoveCreditBalance = async (
  { accountGuid }: { accountGuid: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<CoveCreditBalance>(
    `/account/${accountGuid}/coveCreditBalance`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getCoveCreditBalance;
