import service, { PandoResponse } from '..';

export interface MakePaymentResponse {
  BalanceStatus: string;
  CurrentBalance: number;
  PaidDate: string;
}

const makePayment = async (
  {
    accountGuid,
    PaymentAmount,
  }: {
    accountGuid: string;
    PaymentAmount?: number;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<PandoResponse<MakePaymentResponse>>(
    `/api/makePayment`,
    { paymentAmount: PaymentAmount, accountGuid },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default makePayment;
