import service from '..';

const postFeedback = async (
  {
    accountGuid,
    content,
  }: {
    accountGuid: string;
    content: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<{ message: string }>(
    `/account/${accountGuid}/feedback`,
    {
      content,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default postFeedback;
