export function isFloat(val: any): boolean {
  return Number(val) == val && val % 1 !== 0;
}

export function isInt(val: any): boolean {
  return Number(val) == val && val % 1 === 0;
}

export function isString(val: any): boolean {
  return typeof val === 'string';
}

export function parseNumber(val: number | string): number {
  if (isFloat(val) || isInt(val)) {
    return Number(val);
  }

  if (isString(val) && !isNaN(Number(val))) {
    return Number(val);
  }

  return 0;
}

export function roundTo(val: number | string, precision = 2): number {
  const parsed = val ? parseNumber(val) : 0;
  const rounded = Math.round(parsed * 100) / 100;
  const fixed = rounded.toFixed(precision);
  return parseFloat(fixed);
}
