import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import {
  CoveAccountInfo,
  getAccountInfo,
} from '@customer/services/coveService';
import { persist, ThunkOn, thunkOn } from 'easy-peasy';
import { StoreModel } from '@customer/state/store/model';

export type Token = Promise<string> | null;

export interface AccountInfoFetchPayload {
  accountsId?: string;
}
export interface AccountInfoModel
  extends DataModel<CoveAccountInfo, any, AccountInfoFetchPayload> {
  onAccountsSetCurrentId: ThunkOn<AccountInfoModel, any, StoreModel>;
}

const accountInfo = persist<AccountInfoModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId =
          payload?.accountsId || storeState?.activeId.currentId || localStorage.getItem('currentId');
        if (currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken = await storeActions.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }
        const response = await getAccountInfo({ accountGuid }, { accessToken });

        return [{ AcctId: accountGuid, ...response?.data }];
      },
      {
        idKey: 'AcctId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.resetMemorizeInitialFetch();
          actions.initialFetch();
        }
      }
    ),
  },
  { deny: ['error'] }
);

export default accountInfo;
