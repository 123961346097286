import service from '@customer/services/coveService/service';

export interface CoveCreditBalanceHistory {
  amount: number;
  appliedCredit: number;
  total: number;
  credit: boolean;
  debit: boolean;
  transactionDate: string;
}

export interface CoveCreditBalanceHistoryResponseBody {
  accountId: string;
  history: CoveCreditBalanceHistory[];
  pagination: { limit: 50; offset: 0; size: 1; total: 1 };
}

const getCoveCreditBalanceHistory = async (
  { accountGuid }: { accountGuid: string },
  {
    accessToken,
    limit = 100,
    offset = 0,
  }: { accessToken: string; limit?: number; offset?: number }
) => {
  const query = new URLSearchParams({
    limit: `${limit}`,
    offset: `${offset}`,
  });
  const response = service.get<CoveCreditBalanceHistoryResponseBody>(
    `/account/${accountGuid}/coveCreditBalance/history?${query}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getCoveCreditBalanceHistory;
