import service, {
  PandoResponse,
} from '@customer/services/pandoService/service';

const setDefaultPaymentMethod = async (
  {
    accountGuid,
    paymentMethodId,
  }: { accountGuid: string; paymentMethodId: number },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<PandoResponse<undefined>>(
    `/api/setPaymentMethod`,
    { PaymentMethodId: paymentMethodId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accountguid: accountGuid,
      },
    }
  );
  return response;
};

export default setDefaultPaymentMethod;
