import axios from 'axios';

export interface PandoResponseError {
  Message: string;
  Source: string;
  ErrorCode: number;
  ErrorSubCode: number;
  IsReported: boolean;
}

export interface PandoResponse<D> {
  Data: D;
  Successful: boolean;
  Errors: PandoResponseError[];
  Stats: string[];
}

const service = axios.create({
  baseURL: ``,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default service;
