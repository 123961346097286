import find from 'lodash/find';
import type coveCart from './coveCart';

type Cart = Awaited<ReturnType<typeof coveCart>>;

export const DISCONTINUED_PRODUCTS_MAP: Record<string, string | null> = {
  '2G-CO-345-1': '2GIG-CO8-345',
  'CP-SCC01-345-1': '2GIG-SMKT8-345',
  '2G-SH-345-1': '2GIG-SMKT8-345',
  '2G-SHF-345-1': '2GIG-SMKT8-345',
  'Yi-IC': 'indoor-camera-eufy',
  'CP.Y20BA': 'indoor-camera-eufy',
  'vm-pl': 'vm-pn',
  'bm-pl': 'bm-pn',
  'KAMI.DB.1': 'doorbell-camera-eufy',
  'CP.W12BA': null,
  'CP-PANEL-345-1': 'CPA-HUB-345-1',
  'SKY.TRIM2.S': 'doorbell-camera-eufy',
};

export const CAMERA_SKUS = [
  'indoor-camera-eufy',
  'doorbell-camera-eufy',
  'outdoor-camera-eufy',
];

export const STATIC_SKUS = ['CPA-PANEL-345-1', 'CPA-HUB-345-1'];

const migrateCartData = (cart: Cart) => {
  const itemsToUpdate: { sku: string; qty: number }[] = [];
  Object.keys(DISCONTINUED_PRODUCTS_MAP).forEach(sku => {
    const newSku = DISCONTINUED_PRODUCTS_MAP[sku];
    const existingItem = find(itemsToUpdate, { sku: newSku });
    const oldItemCurrentQty = parseInt(
      `${cart?.cartProducts?.[sku]?.qty ?? 0}` || '0'
    );
    if (!newSku) return;
    const currentQty = parseInt(`${cart?.cartProducts?.[newSku]?.qty}` || '0');
    if (
      sku === 'CPA-PANEL-345-1' &&
      DISCONTINUED_PRODUCTS_MAP[sku] !== undefined &&
      oldItemCurrentQty > 0
    ) {
      cart.setMigratedToAlula(true);
    }
    // Get combined Qty
    const combinedQty = oldItemCurrentQty + (existingItem?.qty || currentQty);

    // Set combined Qty
    if (
      newSku &&
      currentQty &&
      (combinedQty !== currentQty || combinedQty === 0)
    ) {
      if (!existingItem) {
        itemsToUpdate.push({ sku: newSku, qty: combinedQty });
      } else {
        const index = itemsToUpdate.indexOf(existingItem);
        itemsToUpdate.splice(index, 1, { sku: newSku, qty: combinedQty });
      }
    }
    if (oldItemCurrentQty > 0) {
      itemsToUpdate.push({ sku, qty: 0 });
    }
  });

  if (itemsToUpdate.length) {
    cart.updateCartItems(
      itemsToUpdate.filter(
        ({ sku }) => cart?.products?.[sku]?.productType === 'equipment'
      ),
      false
    );
    itemsToUpdate
      .filter(
        ({ sku }) => cart?.products?.[sku]?.productType === 'subscription'
      )
      .forEach(({ sku }) => {
        cart.updateMonitoringPlan(sku);
      });
  }

  const code = cart.promoCode;
  if (code) {
    cart.removePromo(code).then(() => cart.applyPromo(code));
  }
};

type CartCheck = keyof Cart;

const updateCartChecks: Array<CartCheck> = [
  'hasExclusiveCoupon',
  'hasValidCouponCode',
  'coupons',
  'couponCodes',
  'couponCodeData',
  'isReferral',
  'salesRepId',
  'discounts',
];

//@ts-ignore
const initialCart: Cart = {
  affirmAmount: undefined,
  affirmExpires: undefined,
  affirmId: undefined,
  loading: false,
  lastSaveDuration: 0,
  cartId: '',
  salesRepId: null,
  referralCode: undefined,
  cartItemIds: [],
  cartProducts: {},
  customer: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    postcode: '',
  },
  products: {},
  subscriptions: {},
  discounts: [],
  discountBreakdown: [],
  payLater: {
    maximum: 0,
    minimum: 0,
    restrictTo: [],
  },
  appliedDiscounts: [],
  monitoringPlan: null,
  equipmentTaxRate: 0,
  monitoringTaxRate: 0,
  taxCalculated: false,
  taxPostcode: '',
  subscriptionIds: [],
  payLaterSubscriptionIds: [],
  valueSubscriptionIds: [],
  isPayLater: false,
  isCartSaved: false,
  isQuizSkipped: false,
  isValue: false,
  coupons: [],
  couponCodes: [],
  couponCodeData: {},
  exclusiveType: {
    thanks: false,
    benefits: false,
    type: '',
  },
  hasExclusiveCoupon: false,
  hasValidCouponCode: false,
  promoCode: null,
  promoCodeData: {},
  promoCodeDescription: [],
  promoCodeDiscounts: [],
  promoCodeValid: null,
  promoCodeExclusive: false,
  quizData: {},
  freeMonths: 1,
  isReferral: false,
  noSensors: false,
  totals: {
    appliedDiscounts: [],
    cartItemIds: [],
    freeMonths: 0,
    isValue: false,
    monitoringPlan: {},
    orderLineItems: {},
    orderTotals: {},
    promotionDiscounts: [],
    shippingMethod: 'FREE-Regular',
    isPayLater: false,
    unformattedTotals: {},
    totals: {
      allEquipmentGrandTotal: '0.00',
      coveCreditAppliedTotal: '0.00',
      equipmentBaseTotal: '0.00',
      equipmentCreditAppliedTotal: '0.00',
      equipmentGrandTotal: '0.00',
      equipmentRetailTotal: '0.00',
      equipmentRetailTotalTax: '0.00',
      equipmentRetailTotalWithTax: '0.00',
      equipmentTotal: '0.00',
      equipmentTotalTax: '0.00',
      equipmentTotalWithTax: '0.00',
      monitoringBaseTotal: '0.00',
      monitoringRetailTotal: '0.00',
      monitoringRetailTotalTax: '0.00',
      monitoringRetailTotalWithTax: '0.00',
      monitoringTotal: '0.00',
      monitoringTotalTax: '0.00',
      monitoringTotalWithTax: '0.00',
      orderBaseTotal: '0.00',
      orderGrandTotal: '0.00',
      orderRetailTotal: '0.00',
      orderRetailTotalTax: '0.00',
      orderRetailTotalWithTax: '0.00',
      orderTotal: '0.00',
      orderTotalDiscount: '231.00',
      orderTotalTax: '0.00',
      orderTotalWithTax: '0.00',
      shippingTotal: '0.00',
    },
    cartProducts: {},
  },
  autoSaveQueuedFn: null,
  isPayLaterEligible: false,
  blacklistedPostcode: false,
  returningUser: false,
  surveysTaken: [],
  cameraType: [],
  applyCoveCreditAmount: 0,
  migratedToAlula: false,
  shippingMethod: 'FREE-Regular',
  efTransactionId: null,
  lastSave: null,
};

export { migrateCartData, updateCartChecks, initialCart };
