import jwt_decode from 'jwt-decode';
import {
  action,
  Action,
  Actions,
  Helpers,
  persist,
  thunk,
  Thunk,
  thunkOn,
  ThunkOn,
} from 'easy-peasy';
import debounce from 'lodash/debounce';
import type { StoreModel } from '@customer/state/store/model';

export type Token = string | null;
export type TokenPayload =
  | (Record<string, any> & {
      exp?: number;
      accountId?: number;
      accountGuid?: string;
      aud?: string;
      scope?: string[];
    })
  | null;

export interface OneTimeUserModel {
  token: Token | null;
  setToken: Action<OneTimeUserModel, Token | null>;
  payload: TokenPayload | null;
  setPayload: Action<OneTimeUserModel, TokenPayload | null>;
  isLoggedIn: boolean;
  setIsLoggedIn: Action<OneTimeUserModel, boolean>;
  setOneTimeToken: Thunk<OneTimeUserModel, Token | null, any, StoreModel>;
  setOneTimeTokenError: Thunk<OneTimeUserModel, unknown, any, StoreModel>;
  getToken: Thunk<
    OneTimeUserModel,
    undefined,
    any,
    StoreModel,
    Promise<string | null> | undefined
  >;
  resetLock: boolean;
  setResetLock: Action<OneTimeUserModel, boolean>;
  reset: Thunk<OneTimeUserModel, undefined, any, StoreModel>;
  onStoreReset: ThunkOn<OneTimeUserModel, any, StoreModel>;
  onSetIsLoggedIn: ThunkOn<OneTimeUserModel, any, StoreModel>;
}

const oneTimeUser = persist<OneTimeUserModel>(
  {
    token: null,
    setToken: action((state, payload) => {
      state.token = payload;
    }),
    payload: null,
    setPayload: action((state, payload) => {
      state.payload = payload;
    }),
    isLoggedIn: false,
    setIsLoggedIn: action((state, payload) => {
      state.isLoggedIn = payload;
    }),
    setOneTimeToken: thunk(async (actions, payload, helpers) => {
      try {
        if (!payload) return;

        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const tokenPayload = jwt_decode<TokenPayload>(payload);
        if (storeState.user.isLoggedIn) {
          actions.setResetLock(true);
          await storeActions.reset();
          actions.setResetLock(false);
        }
        actions.setToken(payload);
        actions.setPayload(tokenPayload);
        actions.setIsLoggedIn(true);
      } catch (error) {
        actions.setOneTimeTokenError(error);
      }
    }),
    setOneTimeTokenError: thunk(async (actions, payload, helpers) => {
      console.error('oneTimeUser setToken decode Error', payload);
      actions.reset();
    }),
    getToken: thunk(
      debounce(
        async (
          actions: Actions<OneTimeUserModel>,
          payload: undefined,
          helpers: Helpers<OneTimeUserModel, StoreModel, any>
        ) => {
          const storeActions = helpers.getStoreActions();
          const state = helpers.getState();
          if (state.token !== null) {
            try {
              const tokenPayload = jwt_decode<TokenPayload>(await state.token);
              if (tokenPayload?.exp && tokenPayload?.exp * 1000 > Date.now()) {
                return state.token;
              }
            } catch (error) {
              console.log('getting new token');
              console.debug(state.token, error, state);
              await storeActions.reset();
            }
          }
          return null;
        },
        500,
        { leading: true, trailing: false }
      )
    ),
    resetLock: false,
    setResetLock: action((state, payload) => {
      state.resetLock = payload;
    }),
    reset: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();
      if (!state.resetLock) {
        actions.setToken(null);
        actions.setPayload(null);
        actions.setIsLoggedIn(false);
      }
    }),
    onStoreReset: thunkOn(
      (actions, storeActions) => storeActions.reset,
      async (actions, target, helpers) => {
        actions.reset();
      }
    ),
    onSetIsLoggedIn: thunkOn(
      (actions, storeActions) => storeActions.user.setIsLoggedIn,
      async (actions, target, helpers) => {
        if (target.payload) {
          actions.reset();
        }
      }
    ),
  },
  {
    allow: [
      // @ts-ignore
      'do_not_store_tokens',
    ],
  }
);

export default oneTimeUser;
