import { useStoreActions } from '@customer/hooks/useStore';
import { Hub } from '@shared/react-auth/configureAmplify';
import React, { useEffect } from 'react';

const AmplifyProvider: React.FC = ({ children }) => {
  const setCognitoUser = useStoreActions(
    actions => actions?.user?.setCognitoUser
  );
  const isBrowser = typeof window !== 'undefined';
  useEffect(() => {}, [isBrowser, setCognitoUser]);
  return <>{children}</>;
};

export default AmplifyProvider;
