import { createStore, thunk } from 'easy-peasy';
import { StoreModel } from './model';
import storeModel from './models';

const store = createStore<StoreModel>({
  ...storeModel,
  reset: thunk((actions, payload, helpers) => {
    store.persist.clear();
    console.log('reset');
  }),
});

// Wrapping dev only code like this normally gets stripped out by bundlers
// such as Webpack when creating a production build.
if (process.env.NODE_ENV === 'development') {
  console.log(module.hot);
  if (module.hot) {
    module.hot.accept(
      ['./models'],
      () => {
        store.reconfigure(storeModel); // 👈 Here is the magic
      },
      (err, { moduleId, dependencyId }) => {
        console.error(err, { moduleId, dependencyId });
      }
    );
  }
  store.subscribe(() => {
    console.debug('A state changed occurred', store.getState());
  });
}

export default store;
