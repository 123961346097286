import service from '..';

export interface OnBehalfOfPandoToken {
  access_token: string;
}

const getOnBehalfOfPandoToken = async (
  {
    idToken,
    clientId,
    pandoClientId,
  }: {
    idToken: string;
    clientId: string;
    pandoClientId: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.post<OnBehalfOfPandoToken>(
    `/oauth2/on-behalf-of/pando/token`,
    {
      id_token: idToken,
      client_id: clientId,
      pandoClientId,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getOnBehalfOfPandoToken;
