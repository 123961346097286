export interface Config {
  URL: string;
  COVE_API_V4_URL: string;
  PANDO_API_URL: string;
  PANDO_API_CLIENT_ID: string;
  DATOCMS_GRAPHQL_URL: string;
  COVE_GRAPHQL_TOKEN: string;
  COGNITO_REGION: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_USER_POOL_WEB_CLIENT_ID: string;
}

const environments: Record<string, Config> = {
  local: {
    URL: 'http://localhost:8010',
    COVE_API_V4_URL: 'http://localhost:3010/staging',
    PANDO_API_URL: 'https://cove.pandolink.com',
    PANDO_API_CLIENT_ID: '46FF8845-C8B9-4A4B-9B31-32D1F70356D3',
    DATOCMS_GRAPHQL_URL: 'https://graphql.datocms.com',
    COVE_GRAPHQL_TOKEN: '8142069ea669831520d57b8da640f0',
    COGNITO_REGION: 'us-east-1',
    COGNITO_USER_POOL_ID: 'us-east-1_G4UA83Tyh',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '2gafsv9fpm5g7bvsom07q4j7dj',
  },
  development: {
    URL: 'http://localhost:8010',
    COVE_API_V4_URL: 'https://api.covestage.com/v4',
    PANDO_API_URL: 'https://cove.pandolink.com',
    PANDO_API_CLIENT_ID: '46FF8845-C8B9-4A4B-9B31-32D1F70356D3',
    DATOCMS_GRAPHQL_URL: 'https://graphql.datocms.com',
    COVE_GRAPHQL_TOKEN: '8142069ea669831520d57b8da640f0',
    COGNITO_REGION: 'us-east-1',
    COGNITO_USER_POOL_ID: 'us-east-1_G4UA83Tyh',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '2gafsv9fpm5g7bvsom07q4j7dj',
  },
  pando: {
    URL: 'https://portal.covestage.com',
    COVE_API_V4_URL: 'https://api.covestage.com/pando',
    PANDO_API_URL: 'https://dev.pandolink.com',
    PANDO_API_CLIENT_ID: '46FF8845-C8B9-4A4B-9B31-32D1F70356D3',
    DATOCMS_GRAPHQL_URL: 'https://graphql.datocms.com',
    COVE_GRAPHQL_TOKEN: '8142069ea669831520d57b8da640f0',
    COGNITO_REGION: 'us-east-1',
    COGNITO_USER_POOL_ID: 'us-east-1_G4UA83Tyh',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '2gafsv9fpm5g7bvsom07q4j7dj',
  },
  staging: {
    URL: 'https://portal.covestage.com',
    COVE_API_V4_URL: 'https://api.covestage.com/v4',
    PANDO_API_URL: 'https://cove.pandolink.com',
    PANDO_API_CLIENT_ID: '46FF8845-C8B9-4A4B-9B31-32D1F70356D3',
    DATOCMS_GRAPHQL_URL: 'https://graphql.datocms.com',
    COVE_GRAPHQL_TOKEN: '8142069ea669831520d57b8da640f0',
    COGNITO_REGION: 'us-east-1',
    COGNITO_USER_POOL_ID: 'us-east-1_G4UA83Tyh',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '2gafsv9fpm5g7bvsom07q4j7dj',
  },
  production: {
    URL: 'https://portal.covesmart.com',
    COVE_API_V4_URL: 'https://api.covesmart.com/v4',
    PANDO_API_URL: 'https://cove.pandolink.com',
    PANDO_API_CLIENT_ID: '46FF8845-C8B9-4A4B-9B31-32D1F70356D3',
    DATOCMS_GRAPHQL_URL: 'https://graphql.datocms.com',
    COVE_GRAPHQL_TOKEN: '8142069ea669831520d57b8da640f0',
    COGNITO_REGION: 'us-east-1',
    COGNITO_USER_POOL_ID: 'us-east-1_G4UA83Tyh',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '2gafsv9fpm5g7bvsom07q4j7dj',
  },
};

const overrides: Partial<Config> = JSON.parse(
  JSON.stringify({
    URL: process.env.GATSBY_URL,
    COVE_API_V4_URL: process.env.GATSBY_COVE_API_V4_URL,
    PANDO_API_URL: process.env.GATSBY_PANDO_API_URL,
    PANDO_API_CLIENT_ID: process.env.GATSBY_PANDO_API_CLIENT_ID,
    DATOCMS_GRAPHQL_URL: process.env.GATSBY_DATOCMS_GRAPHQL_URL,
    COVE_GRAPHQL_TOKEN: process.env.GATSBY_COVE_GRAPHQL_TOKEN,
    COGNITO_REGION: process.env.GATSBY_COGNITO_REGION,
    COGNITO_USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID,
    COGNITO_USER_POOL_WEB_CLIENT_ID:
      process.env.GATSBY_COGNITO_USER_POOL_WEB_CLIENT_ID,
  })
);

const config: Config = {
  ...(environments[process.env.GATSBY_API_ENV] || environments.development),
  ...overrides,
};

export default config;
