import { storageFactory } from './storageFactory';

const defaultStorageFactory = {
  setItem: () => null,
  getItem: () => null,
  /**
   *
   * @param {string} item
   * @returns
   */
  removeItem: item => null,
};

export const localStore =
  typeof window !== 'undefined'
    ? storageFactory(localStorage)
    : defaultStorageFactory;
export const sessionStore =
  typeof window !== 'undefined'
    ? storageFactory(sessionStorage)
    : defaultStorageFactory;
