import { Amplify } from 'aws-amplify';

export { Amplify };
export { Auth, Hub } from 'aws-amplify';

/**
 * @typedef AmplifyAuthConfiguration
 * @property {string} region
 * @property {string} userPoolId
 * @property {string} userPoolWebClientId
 * @property {string} [authenticationFlowType]
 */

/**
 *
 * @param {AmplifyAuthConfiguration} config
 */
const configureAmplify = config => {
  Amplify.configure({
    Auth: config,
  });
};

export default configureAmplify;
