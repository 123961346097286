import jwt_decode from 'jwt-decode';
import {
  action,
  Action,
  Actions,
  Helpers,
  persist,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from 'easy-peasy';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import { Auth } from '@shared/react-auth/configureAmplify';
import { StoreModel } from '../model';

export interface CognitoUserAttributes {
  sub: string;
  email_verified: boolean;
  email: string;
}

export interface CognitoUserSession {
  idToken: {
    jwtToken: string;
  };
  accessToken: {
    jwtToken: string;
  };
}

export interface CognitoUser {
  username: string;
  signInUserSession: CognitoUserSession;
  authenticationFlowType: string;
  keyPrefix: string;
  userDataKey: string;
  attributes: CognitoUserAttributes;
  preferredMFA: string;
}

export interface User {
  username: string;
  attributes: CognitoUserAttributes;
  preferredMFA: string;
}

export interface UserModel {
  accessToken: Promise<string> | string | null;
  idToken: Promise<string> | string | null;
  getTokens: Thunk<
    UserModel,
    undefined,
    any,
    StoreModel,
    | Promise<{
        accessToken: string | null;
        idToken: string | null;
      }>
    | undefined
  >;
  setTokens: Action<
    UserModel,
    {
      accessToken: Promise<string> | string | null;
      idToken: Promise<string> | string | null;
    }
  >;
  isLoggedIn: boolean;
  setIsLoggedIn: Action<UserModel, boolean>;
  user: User | null;
  setUser: Action<UserModel, User | null>;
  setCognitoUser: Thunk<UserModel, CognitoUser | undefined, any, StoreModel>;
  setCognitoUserMemoizeCacheClear: Thunk<
    UserModel,
    CognitoUser | undefined,
    any,
    StoreModel
  >;
  setCurrentSession: Thunk<
    UserModel,
    CognitoUserSession | undefined,
    any,
    StoreModel,
    Promise<{
      accessToken: string;
      idToken: string;
    }>
  >;
  logout: Thunk<UserModel, undefined, any, StoreModel>;
  reset: Thunk<UserModel, undefined, any, StoreModel>;
  onStoreReset: ThunkOn<UserModel, any, StoreModel>;
}

const user = () => {
  const setCognitoUserMemoize = memoize(
    async (
      actions: Actions<UserModel>,
      payload: CognitoUser | undefined,
      helpers: Helpers<UserModel, StoreModel, any>
    ) => {
      const cognitoUser: CognitoUser =
        payload || (await Auth.currentAuthenticatedUser());
      actions.setUser(cognitoUser);
      actions.setUser({
        username: cognitoUser.username,
        attributes: cognitoUser.attributes,
        preferredMFA: cognitoUser.preferredMFA,
      });
      await actions.setCurrentSession(cognitoUser.signInUserSession);

      const storeActions = helpers.getStoreActions();
      await storeActions.pando.accounts.initialFetch();

      actions.setIsLoggedIn(true);
    }
  );

  return persist<UserModel>(
    {
      accessToken: null,
      idToken: null,
      getTokens: thunk(
        debounce(
          async (
            actions: Actions<UserModel>,
            payload: undefined,
            helpers: Helpers<UserModel, StoreModel, any>
          ) => {
            const state = helpers.getState();
            const accessToken = await state.accessToken;
            const idToken = await state.idToken;

            if (accessToken !== null) {
              try {
                const accessTokenPayload = jwt_decode<{ exp?: number }>(
                  accessToken
                );
                if (
                  accessTokenPayload?.exp &&
                  accessTokenPayload?.exp * 1000 > Date.now()
                ) {
                  return {
                    accessToken,
                    idToken,
                  };
                }
              } catch (error) {
                console.log('getting new token');
              }
            }

            const currentSession = await actions.setCurrentSession();
            if (!currentSession?.accessToken || !currentSession?.idToken) {
              throw new Error('not logged in');
            }
            return {
              accessToken: currentSession?.accessToken,
              idToken: currentSession?.idToken,
            };
          },
          500,
          { leading: true, trailing: false }
        )
      ),
      setTokens: action((state, payload) => {
        state.accessToken = payload.accessToken;
        state.idToken = payload.idToken;
      }),

      isLoggedIn: false,
      setIsLoggedIn: action((state, payload) => {
        state.isLoggedIn = !!payload;
      }),

      user: null,
      setUser: action((state, payload) => {
        state.user = payload;
      }),
      setCurrentSession: thunk(async (actions, payload, helpers) => {
        const state = helpers.getState();
        const idToken = payload?.idToken?.jwtToken || state.idToken;
        if (payload?.accessToken?.jwtToken && idToken) {
          const tokens = {
            accessToken: payload?.accessToken?.jwtToken,
            idToken: payload?.idToken?.jwtToken,
          };
          actions.setTokens(tokens);
          return tokens;
        }

        const currentSessionPromise = Auth.currentSession();
        const accessTokenPromise = (async () =>
          (await currentSessionPromise).getAccessToken().getJwtToken())();
        const idTokenPromise = (async () =>
          (await currentSessionPromise).getIdToken().getJwtToken())();

        const tokensWithAccessTokenPromise = {
          accessToken: accessTokenPromise,
          idToken: idTokenPromise,
        };
        actions.setTokens(tokensWithAccessTokenPromise);

        const currentSession = await currentSessionPromise;

        const tokens = {
          accessToken: currentSession.getAccessToken().getJwtToken(),
          idToken: currentSession.getIdToken().getJwtToken(),
        };
        actions.setTokens(tokens);

        return tokens;
      }),
      setCognitoUser: thunk(setCognitoUserMemoize),
      setCognitoUserMemoizeCacheClear: thunk(
        async (actions, payload, helpers) => {
          setCognitoUserMemoize?.cache?.clear?.();
        }
      ),
      logout: thunk(async (actions, payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        localStorage.clear();
        Auth.signOut();
        await storeActions.reset();
      }),
      reset: thunk(async (actions, payload, helpers) => {
        actions.setIsLoggedIn(false);
        actions.setUser(null);
        actions.setTokens({
          accessToken: null,
          idToken: null,
        });
        actions.setCognitoUserMemoizeCacheClear();
      }),
      onStoreReset: thunkOn(
        (actions, storeActions) => storeActions.reset,
        async (actions, target, helpers) => {
          actions.reset();
        }
      ),
    },
    {
      allow: [
        // @ts-ignore
        'do_not_store_tokens',
        'isLoggedIn',
      ],
    }
  );
};

export default user;
