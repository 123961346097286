import find from 'lodash/find';

/**
 * @type {Object.<string, string | null>}
 */
export const DISCONTINUED_PRODUCTS_MAP = {
  '2G-CO-345-1': '2GIG-CO8-345',
  'CP-SCC01-345-1': '2GIG-SMKT8-345',
  '2G-SH-345-1': '2GIG-SMKT8-345',
  '2G-SHF-345-1': '2GIG-SMKT8-345',
  'Yi-IC': 'indoor-camera-eufy',
  'CP.Y20BA': 'indoor-camera-eufy',
  'vm-pl': 'vm-pn',
  'bm-pl': 'bm-pn',
  'KAMI.DB.1': null,
  'CP.W12BA': null,
  'CP-PANEL-345-1': 'CPA-HUB-345-1',
  'SKY.TRIM2.S': null,
};

const migrateCartData = cart => {
  let itemsToUpdate = [];
  Object.keys(DISCONTINUED_PRODUCTS_MAP).forEach(sku => {
    const newSku = DISCONTINUED_PRODUCTS_MAP[sku];
    const existingItem = find(itemsToUpdate, { sku: newSku });
    const oldItemCurrentQty = parseInt(cart?.cartProducts?.[sku]?.qty || '0');
    const currentQty = parseInt(cart?.cartProducts?.[newSku]?.qty || '0');
    // Get combined Qty
    const combinedQty = oldItemCurrentQty + (existingItem?.qty || currentQty);

    // Set combined Qty
    if (
      newSku &&
      currentQty &&
      (combinedQty !== currentQty || combinedQty === 0)
    ) {
      if (!existingItem) {
        itemsToUpdate.push({ sku: newSku, qty: combinedQty });
      } else {
        const index = itemsToUpdate.indexOf(existingItem);
        itemsToUpdate.splice(index, 1, { sku: newSku, qty: combinedQty });
      }
    }
    if (oldItemCurrentQty > 0) {
      itemsToUpdate.push({ sku, qty: 0 });
    }
  });

  if (itemsToUpdate.length) {
    cart.updateCartItems(itemsToUpdate);
  }
};

export default { migrateCartData };
