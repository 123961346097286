import service from '..';

const updateExpiredCard = async (
  {
    accountGuid,
    encryptedCvv,
    expirationDate,
  }: {
    accountGuid: string;
    encryptedCvv: string;
    expirationDate: string;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.patch<{ message: string }>(
    `/account/${accountGuid}/update-expired-card`,
    {
      encryptedCvv,
      expirationDate,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default updateExpiredCard;
