import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import {
  CoveCreditBalanceHistoryResponseBody,
  getCoveCreditBalanceHistory,
} from '@customer/services/coveService';
import {
  action,
  Action,
  persist,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from 'easy-peasy';
import { StoreModel } from '@customer/state/store/model';

export interface CoveCreditBalanceHistoryFetchPayload {
  accountsId?: string;
}

export interface CoveCreditBalanceHistoryFetchMorePayload
  extends CoveCreditBalanceHistoryFetchPayload {
  offset?: number;
  limit?: number;
}

export interface CoveCreditBalanceHistoryModel
  extends DataModel<
    CoveCreditBalanceHistoryResponseBody,
    any,
    CoveCreditBalanceHistoryFetchPayload
  > {
  onAccountsSetCurrentId: ThunkOn<
    CoveCreditBalanceHistoryModel,
    any,
    StoreModel
  >;
  updateCoveCreditBalanceHistory: Action<
    CoveCreditBalanceHistoryModel,
    CoveCreditBalanceHistoryFetchMorePayload & {
      accountGuid: string;
      data: CoveCreditBalanceHistoryResponseBody;
    }
  >;
  fetchMoreCoveCreditBalanceHistory: Thunk<
    CoveCreditBalanceHistoryModel,
    CoveCreditBalanceHistoryFetchMorePayload,
    any,
    StoreModel
  >;
}

const monitoringPlan = persist<CoveCreditBalanceHistoryModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId =
          payload?.accountsId || storeState?.activeId?.currentId || localStorage.getItem('currentId');
        if (currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken = await storeActions.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }
        const response = await getCoveCreditBalanceHistory(
          { accountGuid },
          { accessToken }
        );

        return [{ AcctId: accountGuid, ...response?.data }];
      },
      {
        idKey: 'AcctId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.resetMemorizeInitialFetch();
          actions.initialFetch();
        }
      }
    ),

    updateCoveCreditBalanceHistory: action((state, payload) => {
      const { accountGuid, limit, offset, data } = payload;
      if (!data?.history?.length) return;

      state.data = state.data || {};
      state.data[accountGuid] = state?.data?.[accountGuid] || {
        id: accountGuid,
        pagination: {
          total: 0,
        },
      };
      state.data[accountGuid].pagination.total =
        data.pagination.total || state.data[accountGuid].pagination.total || 0;
      state.data[accountGuid].history = state.data[accountGuid].history || [];
      const start = offset || 0;
      if (start) {
        for (let index = 0; index < start; index++) {
          state.data[accountGuid].history[index] =
            state.data[accountGuid].history[index] || {};
        }
      }
      const prepareRecordsCount = Math.min(
        state.data[accountGuid].pagination.total,
        limit || data?.history?.length
      );
      for (let index = 0; index < prepareRecordsCount; index++) {
        state.data[accountGuid].history[start + index] = data.history[index];
      }
    }),
    fetchMoreCoveCreditBalanceHistory: thunk(
      async (actions, payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId = storeState?.activeId?.currentId || localStorage.getItem('currentId');
        if (currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          payload?.accountsId ||
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken = await storeActions.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }

        const loadExtra = (payload?.limit || 10) * 3;
        const limit = Math.max(0, payload?.limit || 10) + loadExtra || 10;
        const offset = Math.max(0, (payload?.offset || 0) - loadExtra);

        const response = await getCoveCreditBalanceHistory(
          { accountGuid },
          { accessToken, limit, offset }
        );

        actions.updateCoveCreditBalanceHistory({
          accountGuid,
          limit,
          offset,
          data: response?.data,
        });
      }
    ),
  },
  { deny: ['error'] }
);

export default monitoringPlan;
