import {
  AccountReferralCode,
  getAccountReferralCode,
} from '@customer/services/coveService';
import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import { StoreModel } from '@customer/state/store/model';
import { persist, thunkOn, ThunkOn } from 'easy-peasy';

export interface AccountReferralCodeFetchPayload {
  accountsId?: string;
}

export interface AccountReferralCodeModel
  extends DataModel<AccountReferralCode, any, AccountReferralCodeFetchPayload> {
  onAccountsSetCurrentId: ThunkOn<AccountReferralCodeModel, any, StoreModel>;
}

const referralCode: AccountReferralCodeModel = persist(
  {
    ...dataModel(
      async (payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        const storeState = helpers.getStoreState();

        const currentId =
          payload?.accountsId || storeState?.activeId?.currentId || '';
        if (!currentId) {
          console.log('MISSING CURRENT ID');
          return [];
        }

        const accountGuid =
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;
        if (!accountGuid) {
          console.log(currentId, 'ACCOUNT_GUID IS NULL');
          return [];
        }

        const accessToken = await storeActions?.pando?.getToken?.();
        if (!accessToken) {
          console.log('ACCESS_TOKEN IS NULL');
          return [];
        }
        const response = await getAccountReferralCode(
          { accountGuid },
          { accessToken }
        );
        return [{ ...response.data, AcctId: accountGuid }];
      },
      {
        idKey: 'AcctId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.resetMemorizeInitialFetch();
          actions.initialFetch();
        }
      }
    ),
  },
  { deny: ['error'] }
);

export default referralCode;
