import axios from 'axios';
import config from '@customer/utils/config';

const service = axios.create({
  baseURL: `${config.COVE_API_V4_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default service;
